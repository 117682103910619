import React from 'react';
import {Input, Button} from 'antd';


import {
    SearchOutlined,
    PlusOutlined
  } from '@ant-design/icons';
import { withRouter } from 'react-router-dom';
class VendorSearchSection extends React.Component {
    state = {
        searchTerm: '',
        vendors: []
    };

    handleSearch = value => {
        // this.setOptions(value ? searchResult(value) : []);
        console.log("in handle search<<<<<<<"+value)
        this.setState({
            searchTerm: value
        }, ()=> {
            this.props.onSearch(value);
        })
        
    }
    
    onSelect = value => {
        //console.log('onSelect', value);

        // this.props.onSearch(value);
    }


    handleChange(tag, checked) {
        const { selectedTags } = this.state;
        const nextSelectedTags = checked ? [...selectedTags, tag] : selectedTags.filter(t => t !== tag);
        console.log('You are interested in: ', nextSelectedTags);
        this.setState({ selectedTags: nextSelectedTags });
      }
    componentDidMount() { 

    }

    showDrawer = () => {
        this.props.onAddVendor();
    }

    render() {

        return <div >
          
            <Input.Search
                placeholder="Search vendor"
                enterButton = {<SearchOutlined size='large'/>}
                size="large"
                style={{marginBottom:'1.5%', width: '50%'}}
                onSearch={this.handleSearch}
                allowClear
                />

            <Button type="primary" onClick={this.showDrawer} style={{float: 'right'}}>
                <PlusOutlined /> New Vendor
            </Button>
            
            {/* <AutoComplete
                dropdownMatchSelectWidth={'90%'}
                style={{marginBottom:'1.5%', width: '50%'}}
                // options={options}
                onSelect={this.onSelect}
            >
                <Input.Search size="large" placeholder="Search product" enterButton />
            </AutoComplete>  */}
        
        </div>
    }
}

export default withRouter(VendorSearchSection);