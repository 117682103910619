import { notification } from "antd"

function showErrorToast(message)
{
    notification.error({message: message, key: "TOAST"})
}

function showSuccessToast(message)
{
    notification.success({message: message, key: "TOAST"})
}

function dismissToast()
{
    notification.close("TOAST")
}
export {showErrorToast, showSuccessToast, dismissToast}
