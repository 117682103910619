import './Products.less';
import React from 'react';
import { Row, Col, Input, Card, Tag} from 'antd';
import {PlusOutlined} from '@ant-design/icons';
class SearchTags extends React.Component {
    state = {
        visible: false,
        tags: [],
        inputVisible: false,
        inputValue: '',
        editInputIndex: -1,
        editInputValue: '',
        active: true,
    };
    showInput = () => {
        if(this.state.active)
        this.setState({ inputVisible: true }, () => this.input.focus());
      };
    
      handleInputChange = e => {
        this.setState({ inputValue: e.target.value });
      };

      getTags = () => {
         return this.state.tags || [];
      }
      
    
      setTags = (tags) =>
      {
         this.setState({
          tags: tags || []
         })
      }

      handleInputConfirm = () => {
        const { inputValue } = this.state;
        let { tags } = this.state;
        if (inputValue && tags.indexOf(inputValue) === -1) {
          tags = [...tags, inputValue];
        }
        //console.log(tags);
        this.setState({
          tags,
          inputVisible: false,
          inputValue: '',
        });
      };
    
      handleEditInputChange = e => {
        this.setState({ editInputValue: e.target.value });
      };
    
      handleEditInputConfirm = () => {
        this.setState(({ tags, editInputIndex, editInputValue }) => {
          const newTags = [...tags];
          newTags[editInputIndex] = editInputValue;
    
          return {
            tags: newTags,
            editInputIndex: -1,
            editInputValue: '',
          };
        });
      };

      saveInputRef = input => {
        this.input = input;
      };
    
      saveEditInputRef = input => {
        this.editInput = input;
      };
      setFormActive = (active) =>
      {
          this.setState({
            active: active,
          })
      }
  
      resetFields = () => {
        this.setState({
          tags: []
        })
      }

      handleClose = removedTag => {
        const tags = this.state.tags.filter(tag => tag !== removedTag);
        console.log(tags);
        this.setState({ tags });
      };
    
      render() {
        const { tags, inputVisible, inputValue, editInputIndex, editInputValue } = this.state;
  
        return (
        <Card title="Search Tags" style={{marginTop: '5%'}}>
            <Row gutter={16}>
                <Col span={24}>
                  <>    
                  {tags.map((tag, index) => {
                    if (editInputIndex === index) {
                        return (
                        <Input
                            ref={this.saveEditInputRef}
                            key={tag}
                            size="small"
                            className="tag-input"
                            value={editInputValue}
                            onChange={this.handleEditInputChange}
                            onBlur={this.handleEditInputConfirm}
                            onPressEnter={this.handleEditInputConfirm}
                        />
                        );
                    }

                    

                    const tagElem = (
                        <Tag
                        className="edit-tag"
                        key={tag}
                        closable
                        onClose={() => this.handleClose(tag)}
                        >
                        <span
                            onDoubleClick={e => {
                            if (index !== 0) {
                                this.setState({ editInputIndex: index, editInputValue: tag }, () => {
                                this.editInput.focus();
                                });
                                e.preventDefault();
                            }
                            }}
                        >
                            {tag}
                        </span>
                        </Tag>
                    );
                    return tagElem;
                    })}
                    {inputVisible && (
                    <Input
                        ref={this.saveInputRef}
                        type="text"
                        size="small"
                        className="tag-input"
                        value={inputValue}
                        onChange={this.handleInputChange}
                        onBlur={this.handleInputConfirm}
                        onPressEnter={this.handleInputConfirm}
                    />
                    )}
                    {!inputVisible && (
                    <Tag  onClick={this.showInput}>
                        <PlusOutlined /> New Tag
                    </Tag>
                    )}
                    </>
                
                </Col>
                
            </Row>
            {
              !this.state.active ? <div className='tag-disabled noselect'/>  : null
            }
        </Card>  
        );
    }
}
export default SearchTags;