import './Products.less';
import React from 'react';
import { Form, Row, Col, Input, Switch, Card, InputNumber, Tree} from 'antd';
import { baseUrl } from "../utils/baseUrl";
import axios from "axios";
import {CheckOutlined, CloseOutlined, DownOutlined} from '@ant-design/icons';
class BasicInfo extends React.Component {

    state = {
      categories: [],
      selectedCategories: [],
      active: true,
    }

    dig = (path = '0', level = 1, categories) => {
        const list = [];
        for (let i = 0; i < categories.length; i += 1) {
          
          const category = categories[i];
          const key = category.id;
          const treeNode = {
            title: category.title,
            key,
          };
      
          if (level > 0) {
            treeNode.children = this.dig(key, level - 1, category.subCategories);
          }
      
          list.push(treeNode);
        }
        return list;
    }

    componentDidMount()
    {
      this.getCategories()
    }

    resetFields = () => {
        this.setState({
          selectedCategories: []
        })
    }

    async getSubcategories(categories)
    {
       await Promise.all(categories.map(async (category) => {
        await axios.get(baseUrl + "category/sub/"+category.id, {withCredentials: true}).then(response => {
              const subCategories = response.data;
             
              category.changingFeatured = false
              category.changingActive = false
              category.subCategories = subCategories
          })
          .catch(error => {
            category.subCategories = []
          });

      }))

      

      this.setState({
        categories: categories,

      });

    }

    onCheck = (value) => {
    
      // console.log("selected >>>>>id"+value+":f:"+this.props.formRef);
      this.setCatChecked(value)
      this.props.formRef.current.setFieldsValue({
          category: this.getSelectedCategories(value)
      })
    }

    setCatChecked = (categories) => {

      // console.log("in set checked<<<<<<<<<"+categories.length)

        this.setState({
          selectedCategories: categories
        })
        this.props.formRef.current.setFieldsValue({
          category: this.getSelectedCategories(categories)
       })

    }

    getSelectedCategories = (value) => {

         const selectedCategories = [] 

         this.state.categories.map(category => {
               if(category.subCategories && category.subCategories.length > 0) {
                  category.subCategories.map(subCategory => {
                       if(value.indexOf(subCategory.id) != -1) {
                        selectedCategories.push(subCategory.title)
                       }
                  })
               }
               else {
                  if(value.indexOf(category.id) != -1) {
                    selectedCategories.push(category.title)
                  }
               }
         })
         return selectedCategories
    }

    getCategories()
    {
        axios
        .get(baseUrl + "category/sub/-1", { withCredentials: true })
        .then(response => {
            const categories = response.data || [];
       
            this.getSubcategories(categories)
        })
        .catch(error => {
          console.log("Response", error);
        });
    }
    setFormActive = (active) =>
    {
        this.setState({
          active: active
        })
    }
    render()
    {
        const treeData = this.dig('0', 1, this.state.categories);
        return (
        <Card title="Basic Information" >
        <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name="title"
                label="Title"
                rules={[{ required: true, message: 'Please enter title' }]}
              >
                <Input placeholder="Enter title" disabled = {!this.state.active}/>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="brand"
                label="Brand"
                rules={[{ required: true, message: 'Please enter brand' }]}
              >
                <Input  
                  placeholder="Enter brand"
                  disabled = {!this.state.active}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={24}>
              <Form.Item
                name="description"
                label="Product Description"
                rules={[
                  {
                    required: true,
                    message: 'Please enter description',
                  },
                ]}
              >
                <Input.TextArea rows={4} placeholder="Enter description" disabled = {!this.state.active}/>
              </Form.Item>
            </Col>
          </Row>
         
          <Row gutter={16}>
              <Col span = {8}>
              <Form.Item
                name="featured"
                label="Featured Product"
                valuePropName="checked"
                >
              <Switch checkedChildren={<CheckOutlined />}
                unCheckedChildren={<CloseOutlined />} disabled = {!this.state.active}/>
              </Form.Item>
                
             
              </Col>
              <Col span = {8}>
              <Form.Item
                name="active"
                label="Activate Product"
                valuePropName="checked"
                >
              <Switch checkedChildren={<CheckOutlined />}
                unCheckedChildren={<CloseOutlined />} disabled = {!this.state.active}/>
              </Form.Item>
                
             
              </Col>
              <Col span={8}>
                <Form.Item
                    name="rank"
                    label="Rank"
                    initialValue={1}
                    rules={[{ required: true, message: 'Please set rank' }]}
                >
                    <InputNumber type="number" disabled = {!this.state.active}/>
                </Form.Item>
             </Col>
          </Row>
          {treeData.length > 0 ? 
          <Row gutter={16}>
            <Col span={24}>
                <Form.Item
                    name="category"
                    label="Category / Sub-Category"
                    rules={[{ required: true, message: 'Please select category' }]}
                >
                <Tree treeData={treeData} 
                height={500} 
                defaultExpandAll={true}
                checkedKeys={this.state.selectedCategories}
                checkable
                selectable={false}
                onCheck={this.onCheck}
                disabled = {!this.state.active}
                switcherIcon={<DownOutlined />}/> 
                </Form.Item>
            </Col>
          </Row> : null}
        </Card>
        );
    }
}
export default BasicInfo;