import './Vendors.less';
import React from 'react';
import { baseUrl } from "../utils/baseUrl";
import axios from "axios";
import { Form, Row, Col, Input, Switch, Card, InputNumber, Select} from 'antd';

const { Option } = Select;
class VendorBasicInfo extends React.Component {
    state = {
        active: true
    }
    
    onChange(value) {
        console.log(`selected ${value}`);
    }
      
    onSearch(val) {
      console.log('search:', val);
    }

    componentDidMount()
    {
    }
    setFormActive = (active) =>
    {
        this.setState({
          active: active
        })
    }

   
    render()
    {
        return (
        <Card title="Basic Information" >
          <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  name="name"
                  label="Name"
                  rules={[{ required: true, message: 'Please enter name'}]}
                >
                  <Input placeholder="Enter name" disabled = {!this.state.active}/>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="email"
                  label="Email"
                  rules={[{ required: true, message: 'Please enter email', type: 'email' }]}
                >
                  <Input type="email" placeholder="Enter email" disabled = {!this.state.active}/>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  name="mobile"
                  label="Mobile no"
                  rules={[{ required: true, message: 'Please enter mobile'}]}
                >
                  <Input type="number" placeholder="Enter mobile" disabled = {!this.state.active} maxLength = {10} />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="company"
                  label="Company"
                >
                  <Input type="text" placeholder="Enter company" disabled = {!this.state.active}/>
                </Form.Item>
              </Col>
            </Row>
           

         
        </Card>
       
        );
    }
}
export default VendorBasicInfo;