import './Home.less';
import React from 'react';
import ProductsPage from './products/ProductsPage';
import CategoriesPage from './categories/CategoriesPage';
import { Layout, Menu, Divider, Popconfirm} from 'antd';
import {
    HomeOutlined,
    NotificationOutlined,
    FileOutlined,
    ProfileOutlined,
    UserOutlined,
    ShoppingOutlined,
    ShopOutlined,
    TeamOutlined,
    SettingOutlined,
    LogoutOutlined,
    ExclamationCircleFilled
  } from '@ant-design/icons';
import { withRouter } from 'react-router-dom';
import VendorsPage from './vendors/VendorsPage';

const { Header, Content, Sider } = Layout;


class HomePage extends React.Component {

    state = {
      current: "Products",
     
    };

    logoutUser = () => {
      // localStorage.removeItem("token");
      localStorage.clear();
      this.props.history.push({
        pathname: "/"
      });
    };

    // onCollapse = collapsed => {
        
    //     this.setState({ collapsed, sidebarWidth: collapsed ?'4%': '15%', });
    //   };
 
      handleClick = e => {
        // console.log('click ', e);
        this.setState({ current: e.key});
      };

    onLogout = () => {
     
      // Dismiss manually and asynchronously
      localStorage.clear();
      this.props.history.push({
        pathname: "/"
      });
    };

     renderChild =  current => {
        switch(current)
        {
           case 'Products':
           return (<ProductsPage current={this.state.current}/>);
           case 'Vendors':
           return (<VendorsPage current={this.state.current}/>);
           default:
           return (<CategoriesPage current={this.state.current}/>);
        }
     };

     menuItems  = [
        {
          key: 'Products',
          icon: <ShoppingOutlined />,
          label: 'Products'
        },
        {
          key: 'Categories',
          icon: <ProfileOutlined />,
          label: 'Categories'
        },
        {
          key: 'Vendors',
          icon: <ShopOutlined />,
          label: 'Vendors'
        },
        {
          key: 'Customers',
          icon: <UserOutlined />,
          label: 'Customers'
        },
        {
          key: 'Orders',
          icon: <NotificationOutlined />,
          label: 'Orders'
        },
        {
          key: 'Divider',
          type: 'divider',
          style: {marginTop: '20px'}
        },
        {
          key: 'Admin accounts',
          icon: <TeamOutlined />,
          label: 'Admin accounts'
        },
        {
          key: 'Maintenance',
          icon: <SettingOutlined />,
          label: 'Maintenance'
        },
     ];

    render() {
        return (

            <Layout style={{ minHeight: '100vh' }} className="disable-select">
                
                <Sider /*collapsible collapsed={this.state.collapsed} onCollapse={this.onCollapse}*/ width="15%" style={{
                  overflow: 'auto',
                  height: '100vh',
                  position: 'fixed',
                  left: 0}}>
                <div 
                 className= { !this.state.collapsed ? "logo-sidebar": "logo-sidebar-collapsed"}/>
                  <Divider style={{margin: 0}}/>
                 <Menu theme="light" defaultSelectedKeys={[this.state.current]} mode="inline" onClick={this.handleClick} items = {this.menuItems}>
                 
                    {/* <Menu.Item key="Products" icon={<ShoppingOutlined />} >
                      Products
                    </Menu.Item>
                    <Menu.Item key="Categories" icon={<ProfileOutlined/>}>
                    Categories
                    </Menu.Item>
                    <Menu.Item key="Vendors" icon={<ShopOutlined />}>
                      Vendors
                    </Menu.Item>
                   
                    <Menu.Item key="Customers" icon={<UserOutlined />} >
                      Customers
                    </Menu.Item>
                    <Menu.Item key="Orders" icon={<NotificationOutlined />} >
                      Orders
                    </Menu.Item>
                    <Menu.Divider style={{marginTop: '20px'}} />
                    <Menu.Item key="Admin accounts" icon={<TeamOutlined/>} >
                      Admin accounts
                    </Menu.Item>
                    <Menu.Item key="Maintenance" icon={<SettingOutlined />} >
                      Maintenance
                    </Menu.Item> */}
                </Menu>
               
                </Sider>
                
                <Layout className="site-layout" style={{marginLeft: '15%'}}>
          <Header className="site-layout-background" style={{ padding: '0', position: 'fixed', zIndex: 1, width: '85%'}}>
            <div style={{ margin: '0 16px', color: 'white', fontSize: '1.5em', fontWeight: 'bold', float: 'left'}}>{this.state.current}</div>

            <div style={{float: 'right'}}>
                <Popconfirm
                
                title={<div style={{ marginLeft: '20px', marginBottom: '10px', marginTop: '5px', fontSize: '16px'}}>Are you sure you want to logout?</div>}
                placement="bottomRight"
                okText="Yes"
                cancelText="No"
                onConfirm={this.onLogout}
                icon={<ExclamationCircleFilled style={{fontSize: '25px'}}/>} 
                > 
                  <LogoutOutlined style={{ fontSize: '30px', color: '#fff', marginRight: '16px', marginTop: '16px'}} href="#"/>
                  
                </Popconfirm>
            </div>
          
           
          </Header>
          <Content style={{  marginTop: 64 }}>
           
            <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
            {this.renderChild(this.state.current)}
            </div>
          </Content>
          
        </Layout>
            </Layout>
           
        );
    }
}

export default withRouter(HomePage);