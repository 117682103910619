import { AutoComplete, Button, Card, Col, Input, List, Row } from 'antd';
import React from 'react';
import {
    SearchOutlined,
    CloseOutlined
} from '@ant-design/icons';
import axios from 'axios';
import { baseUrl } from '../utils/baseUrl';

class RecommendProduct extends React.Component {
    state = {
        products: [],
        recommendedProducts: [],
        product: null,
        loading: false,
        searchTerm: '',
        active: true,
    }
    onDelete = (product) => {
        let products = this.state.recommendedProducts;
        const index = products.indexOf(product);
        if (index > -1) {
            products.splice(index, 1); // 2nd parameter means remove one item only
            this.setState({
                recommendedProducts: products
            })
        }
        
    }

    getRecommendedProducts = () => {
        return this.state.recommendedProducts || [];
     }

    onAdd = (product) => {
        //console.log("in add<<<<<<<"+product.title);

        let existingProduct = this.state.recommendedProducts.find(recProduct => recProduct.id === product.id)

        if(!existingProduct) {
            let products = this.state.recommendedProducts;
            products.push(product)
            this.setState({
                recommendedProducts: products
            })
        }

        this.setState({
            searchTerm: '',
            products: [],
        })
        console.log("in add<<<<<<<"+existingProduct+":rr:"+this.state.recommendedProducts.length);
    }

    componentDidMount() {
        if(this.props.product) {
            console.log("in did mount<<<<111<<<<<<"+this.props.product)
            this.setState({
                product: this.props.product,
                recommendedProducts: this.props.product.recommended_products
           })
        }
        
      
    }

    componentDidUpdate(prevProps) {

        
        if(this.props.product !== prevProps.product) {
            console.log("component did update<<<<111<<<<<<"+this.props.product+":prev:"+prevProps.product)
            this.setState({
                product: this.props.product,
                recommendedProducts: this.props.product.recommended_products
            });
        }
    }

    renderProduct = (product) => ({
        value: product.title,
        label: (
            <div>
                <Row gutter={16}>
                    <Col span={3}>
                        <img src={ baseUrl + "media/"+ product.variants[0].image} style={{width: '100%', height: 'auto'}} alt=""/>
                    </Col>
                    <Col span={18}>
                        <div className='rec_title'>{product.title}</div>
                        <div className='rec_desc'>{product.description}</div>
                    </Col>
                    <Col span={3}>
                        <Button onClick={() => {
                            this.onAdd(product);
                        }} 
                        style={{ marginRight: 8, marginTop: 8}} type="primary">
                        Add
                        </Button>
                    </Col>
                </Row> 
                <div style={{width: '100%', height: '1px', backgroundColor: "#eeeeee"}}>

                </div>
            </div>
        ),
    })

    setFormActive = (active) =>
    {
        this.setState({
          active: active,
        })
    }

    resetFields = () => {
        this.setState({
          products:[],
          recommendedProducts:[]
        })
      }

    handleSearch = event => {
        console.log("in value<<<<<<<<<<"+event.target.value);
        const searchTerm = event.target.value;
        var searchStr = "&searchTerm="+searchTerm;

        this.setState({
            searchTerm: searchTerm
        })

        if(searchTerm.length > 1) {
            axios
            .get(baseUrl + "product/find?page=0&limit=10"+searchStr, {withCredentials: true})
            .then(response => {
                var products = response.data;

                if(this.state.product) {
                    products = products.filter(product => (this.state.product.id !== product.id && !this.state.recommendedProducts.find(recProduct => recProduct.id === product.id)));
                    console.log("got [products>>>>>>>>>>"+products.length+":ud:"+this.state.product.id+":rec:"+this.state.recommendedProducts.length);
                }
                else {
                    products = products.filter(product => !this.state.recommendedProducts.find(recProduct => recProduct.id === product.id))
                }
                

                this.setState({
                    loading: false,
                    products: products,
                });
            })
            .catch(error => {
                console.log("Response22222222", error);
            });
        }
        else {
            this.setState({
                loading: false,
                products: [],
            });
        }
    }
    

    render()
    {
        var options = [];

        this.state.products.forEach((product) => {
            options.push(this.renderProduct(product))
        });
        
        return (
            <Card title="Recommendation" style={{marginTop: '5%'}}>

            <AutoComplete
                dropdownMatchSelectWidth={500}
                style={{width: '100%'}}
                options={options}
                onSelect={(value, option) => {
                    this.setState({
                        searchTerm: value
                    })
                    let product = this.state.products.find(product => product.title === value)
                    if(product) {
                        this.onAdd(product)
                    }
                }}
                value={this.state.searchTerm}
                disabled= {this.state.recommendedProducts.length >= 5 || !this.state.active}
            >
            <Input.Search
                placeholder="Search product"
                enterButton = {<SearchOutlined size='large' />}
                size="large"
                onChange={this.handleSearch}
                allowClear
                />
            </AutoComplete>
            
            <List
            style={{marginTop: '2%'}}
            grid={{gutter: 16, column: 5 }}
            dataSource={this.state.recommendedProducts}
            renderItem={product => (
                <List.Item>
                    <Card style={{width: '100%', backgroundColor: '#fafafa'}} bodyStyle={{padding: '2%'}}>
                        <img src={ baseUrl + "media/"+ product.variants[0].image} style={{width: '100%', height: 'auto'}} alt=""/>
                        <div className='rec_section_title'>{product.title}</div>
                        <Button shape="circle" icon={<CloseOutlined />} type="primary" className='rec_close_btn' size='small'  onClick={() => {
                                this.onDelete(product);
                        }} disabled={!this.state.active}/>
                    </Card>
                </List.Item>
            )}
            />
                
            </Card>
        )
    }
}
export default RecommendProduct;