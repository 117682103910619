
import React from 'react';
import { Form, Input, Button, Row, Layout, Card, Col } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import { withRouter } from "react-router-dom";
import logo from "../../assets/images/sidelogo.png";
import axios from "axios";
import { baseUrl } from "../utils/baseUrl";


const layout = {
    labelCol: {
      span: 24,
    },
    wrapperCol: {
      span: 24,
      align: 'left'
    },
  };

  const tailLayout = {
    wrapperCol: {
     
      span: 24,
      
    },
  };

class LoginPage extends React.Component {

    state = {
      status: "",
      loading: false
    };

    formRef = React.createRef();

   
   onFinish = async values => {
  
      console.log("values<<<<<<<<<"+values.sb_username);
    
      this.setState({
        loading: true
      });
      const data = {
          username: values.sb_username,
          password: values.sb_password
      };
    
          await axios.post(baseUrl + "auth/login", data, { withCredentials: true}).then((response) => {
            this.setState({
              loading: false
            });
            console.log("rres status<<<<<<<<"+response.status);
            if (response.status === 200) {
                // window.localStorage.setItem("token", response.data.token);
                window.localStorage.setItem("username", values.sb_username);
                this.props.history.push({
                  pathname: "/"
              });
            } else {
                console.log("response.data.message="+response.data);
                this.setState({ status: "OK" });
                this.clearError();
            }
          }).catch(err => {
            this.setState({
              loading: false,
              status: "OK" 
            });
            this.clearError();
            console.log("Response>>>>>>>>>!11", JSON.stringify(err)+"::status:"+err.status+":msg:"+err.message);
          });
    };

    clearError = () => {
      const timer = setTimeout(() => {
        this.setState({ status: "" });
      }, 3000);
      return () => clearTimeout(timer);
    };

    clearValidation = () => {
      const timer = setTimeout(() => {
      
        this.formRef.current.resetFields();

      }, 3000);
      return () => clearTimeout(timer);
    }
    
    onFinishFailed = errorInfo => {
        this.clearValidation();
    };

    render() {

        console.log("in login page<<<<<<<<<<<");

        return (
           
           <Layout style={{height:"100vh"}}>
               <Layout.Content>
               <Row type="flex" align="middle" style={{marginTop: '10%'}}>
               <Col md={{span: 8, offset: 8}} xxl={{span: 6, offset: 9}}>
               <Card 
                cover={
                  <img
                    alt="Login required"
                    style={{width: '40%', marginTop: '2%'}}
                    src={logo}
                  />
                }
               style={{padding: 15}}
               align="center">
               <Form
                        {...layout}
                        ref={this.formRef}
                        name="basic"
                        initialValues={{
                            remember: false,
                        }}
                        onFinish={this.onFinish}
                        onFinishFailed={this.onFinishFailed}
                        
                    >
                        <Form.Item
                            
                            name="sb_username"
                            
                            rules={[
                            {
                                required: true,
                                message: 'Please input your username!',
                            },
                            ]}
                        >
                             <Input 
                             style={{marginTop: '2%'}}
                             size="large" 
                             prefix={<UserOutlined className="site-form-item-icon" style={{marginRight: '10px'}}/>} 
                             placeholder="Username" />
                        </Form.Item>

                        <Form.Item
                            
                            name="sb_password"
                            
                            rules={[
                            {
                                required: true,
                                message: 'Please input your password!',
                            },
                            ]}
                        >
                            <Input.Password 
                            style={{marginTop: '2%'}}
                            size="large" 
                            autoComplete="new-password"
                            placeholder="Password"
                            prefix={<LockOutlined className="site-form-item-icon" style={{marginRight: '10px'}}/>}
                            />
                        </Form.Item>
                        <Form.Item {...tailLayout} 
                        { ...this.state.status && {
                          help: <div style={{marginTop: 10}}>Invalid username or password</div>,
                          validateStatus: 'error', 
                      }}
                       
                        >
                            <Button type="primary" htmlType="submit" block size="large" loading = {this.state.loading} style={{marginTop: '5%'}}>
                            Submit
                            </Button>
                        </Form.Item>
                    </Form>
               </Card>
               </Col>
               </Row>
               </Layout.Content>
 
           </Layout>
           
           
         
        );
    }
}

export default withRouter(LoginPage);