import React from "react";
import { withRouter } from "react-router-dom";
import { Spin, Table, Space, Button, Tooltip, Switch, Layout, Popconfirm} from 'antd';
import Highlighter from 'react-highlight-words';
import axios from "axios";
import { baseUrl } from "../utils/baseUrl";
import {showSuccessToast, showErrorToast, dismissToast} from '../utils/Utils';
import VendorSearchSection from "./VendorSearchSection";
import {
  
    EditOutlined,
    DeleteOutlined,
    StarOutlined,
    CopyOutlined,
    CheckOutlined,
    CloseOutlined,
    ExclamationCircleFilled,
  } from '@ant-design/icons';
import AddVendor from "./AddVendor";
class VendorsPage extends React.Component 
{
    state = {
        vendors: [],
        vendor: null,
        copyVendor: null,
        searchTerm: '',
    };
    componentDidMount()
    {
        this.getVendors();
      
    }

    onVendorFormClose = () =>
    {
      this.setState({
        addVendor: false,
        loading: true,
        vendor: null,
        vendors: []
      })

      this.getVendors()
    }

    getVendorsByTitle = (searchTerm) => {
      
        this.setState({
          searchTerm,
          
        },() => { this.getVendors(); });
        
    }

    getVendors() {
        this.setState({
            loading: true,
            
         });
         var searchStr = ''
        if(this.state.searchTerm){
          searchStr = "?searchTerm="+this.state.searchTerm
        }
      
        axios
        .get(baseUrl + "vendor/count"+searchStr, { withCredentials: true })
        .then(response => {
            const total = response.data.count;
            this.setState({
               totalVendors: total,
               pageSize: 10,
               currentPage: 1
            });

            this.getVendorsByPage(0, 10);
            // console.log("total111 ="+total);
        })
        .catch(error => {
          showErrorToast('Error getting vendors')
        });
    }

    onAddVendor = () =>
    {
       this.setState({
         addVendor: true
       })
    }

    getVendorsByPage = (page, limit) => {
        this.setState({
            loading: true,
            
         });
    
         var searchStr = ''
         if(this.state.searchTerm){
          searchStr = "&searchTerm="+this.state.searchTerm
         }
        
          axios
          .get(baseUrl + "vendor/find?page="+page+"&limit="+limit+searchStr, { withCredentials: true })
          .then(response => {
            const vendors = response.data;
            vendors.map((vendor) => {
                vendor.changingActive = false
            })
            this.setState({
                loading: false,
                vendors: vendors,
        
              });
            
          })
          .catch(error => {
            showErrorToast('Error getting vendors')
          });
    }


    getVendorsByTitle = (searchTerm) => {
      
      
      this.setState({
        searchTerm,
        
      },() => { this.getVendors(); });
      
   
    }

    onActiveChanged = (vendor) => {
      axios.put(baseUrl + "vendor/"+vendor.id, {
        active: !vendor.active
     },
     { withCredentials: true })
     .then(response => {

        vendor.active = response.data.code == 0 ? !vendor.active : vendor.active
        vendor.changingActive = false
     })
     .catch(error => {
      showErrorToast('Error changing status')
     });
    }

    onEdit = (vendor) => {
      this.setState({
        addVendor: true,
        vendor: vendor
      })
    }

    onDelete = (vendor) => {
      this.setState({
        showDeleteConfirm: true
      })
    }

    onDuplicate = (vendor) => {
      this.setState({
        addVendor: true,
        copyVendor: vendor,
      })
    }

    performDelete = (vendor) => {
      this.setState({
        loading: true,
      })
      axios
        .delete(baseUrl + "vendor/"+vendor.id, { withCredentials: true })
        .then(response => {
            this.getVendors()
        })
        .catch(error => {
          showErrorToast('Error deleting vendor');
        });
    }

    render() {
     const paginationOptions = {
        showSizeChanger: false,
        showQuickJumper: true,
        onShowSizeChange: (_, pageSize) => {
            this.setState({
                pageSize
            });
        },
        onChange: (page) => {
            // console.log("in page<<<<<<<<<"+page)
            this.setState({
              currentPage: page
            })
            this.getVendorsByPage(page - 1, this.state.pageSize);
        },
        pageSizeOptions: [10, 20, 50, 100],
        showTotal: (total, range) => `${range[0]} to ${range[1]} of ${total}`,
      };
  
      const pagination = {
        ...paginationOptions,
        total: this.state.totalVendors,
        current: this.state.currentPage,
        pageSize: this.state.pageSize,
      };

        const columns = [
            {
              title: 'Name',
              dataIndex: 'name',
              key: 'name',
              ellipsis: {
                showTitle: false,
              },
              render: name => (
                <Tooltip placement="topLeft" title={name}>
                  {this.state.searchTerm ?
                  (
                  <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[this.state.searchTerm]}
                    autoEscape
                    textToHighlight={name ? name.toString() : ''}
                  />
                ) : <span>{name}</span>}
                </Tooltip>
              ),
              width: '14%',
              //  ...this.getColumnSearchProps('title'),
            },
            {
              title: 'Mobile',
              dataIndex: 'mobile',
              key: 'mobile',
              render: mobile => (
                <div>{mobile}</div>
              ),
              width: '14%',
              // ...this.getColumnSearchProps('variants'),
            },
            {
                title: 'Email',
                dataIndex: 'email',
                key: 'email',
                render: email => (
                  <div>{email}</div>
                ),
                width: '14%',
                // ...this.getColumnSearchProps('variants'),
            },
            {
                title: 'Address',
                dataIndex: 'address',
                key: 'address',
                render: address => (
                  <div>{address}</div>
                ),
                width: '21%',
                // ...this.getColumnSearchProps('variants'),
            },
            {
                title: 'Company',
                dataIndex: 'company',
                key: 'company',
                render: company => (
                  <div>{company}</div>
                ),
                width: '12%',
                // ...this.getColumnSearchProps('variants'),
            },
            {
                 title: 'Status',
                 dataIndex: 'status',
                 key: 'status',
                 width: '10%',
                 align:'center',
                 render: (text, record) =>
                 <Tooltip title="Activate category"> 
                 <Switch
                    loading={record.changingActive}
                    checkedChildren={<CheckOutlined />}
                    unCheckedChildren={<CloseOutlined />}
                    onChange={(checked) => {
                      if(record.active != checked) {
                        record.changingActive = true
                        this.onActiveChanged(record)
                      }
                    }}
                    defaultChecked={record.active}
                    size="small"
                  />
                 </Tooltip>
              },
              {
                title: 'Options',
                dataIndex: 'options',
                key: 'options',
                align:'center',
                width: '15%',
                render: (text, record) =>
                  <Space>
                    <Tooltip title="Edit vendor"><Button
                      type="primary"
                      shape="circle"
                      size="small"
                      onClick={() => {
                        this.onEdit(record)
                      }}
                      icon={<EditOutlined />}
                      
                    /></Tooltip>
                                        
                    <Tooltip title="Duplicate vendor"> <Button
                      type="primary"
                      shape="circle"
                      size="small"
                      onClick={() => {
                        this.onDuplicate(record)
                      }}
                      icon={<CopyOutlined />}
                      
                    /></Tooltip>
                      <Popconfirm
                
                        title={<div style={{ marginLeft: '20px', marginBottom: '10px', marginTop: '5px', fontSize: '16px'}}>Are you sure you want to delete?</div>}
                        placement="bottomRight"
                        arrowPointAtCenter={true}
                        okText="Yes"
                        cancelText="No"
                        onConfirm={() => {
                          this.performDelete(record)
                        }}
                        icon={<ExclamationCircleFilled style={{fontSize: '25px'}}/>} 
                    > 
                     <Tooltip title="Delete vendor">  
                     
                          <Button
                            type="primary"
                            shape="circle"
                            size="small"
                            onClick={() => {
                              this.onDelete(record)
                            }}
                            icon={<DeleteOutlined />}
                            
                          />
                    </Tooltip>
                    </Popconfirm>
                     {/* <Tooltip title="Feature category">  <Button
                      type="primary"
                      shape="circle"
                      size="small"
                      icon={<StarOutlined />}
                      
                    /></Tooltip> */} 
                    
                  </Space>
              },
          ];
        
        return (
            
          <Layout>
              <VendorSearchSection onSearch = {this.getVendorsByTitle} onAddVendor = {this.onAddVendor}/>
              
              {
                this.state.addVendor ? <AddVendor visible={this.state.addVendor} onClose = {this.onVendorFormClose} vendor = {this.state.vendor} copyVendor = {this.state.copyVendor}/>
                  : null
              }
              
              <Spin spinning={this.state.loading} size='large'>
                <Table columns={columns} dataSource={this.state.vendors} pagination={pagination} rowKey="id" bordered/>
              </Spin>

          </Layout>
             
            
        );
    }
}


export default withRouter(VendorsPage);