import React from "react";
import { Row, Col, Input, InputNumber, Collapse, Upload, Form, Select, Popconfirm} from 'antd';
import { PlusOutlined, DeleteOutlined, LoadingOutlined, ExclamationCircleFilled} from '@ant-design/icons';
import { showErrorToast } from "../utils/Utils";
import { baseUrl } from "../utils/baseUrl";
import ImgCrop from 'antd-img-crop';
import PriceInfo from "./PriceInfo";

const { Option } = Select;
class Variant extends React.Component 
{
    state = {
        loading: false,
        variant: null,
        previewImage: "",
        priceConfig: null,
        active: true,
        vendors: [],
        fileList: [],
    }

    componentDidMount()
    {
        console.log("component did mount<<<<<<<<<<"+JSON.stringify(this.props.priceConfig))
        this.setState({
            variant: this.props.variant,
            vendors: this.props.vendors,
            active: this.props.isActive,
            priceConfig: this.props.priceConfig
        })

        if(this.props.variant.image) {
            setTimeout(() => {
            
                this.setMedia(this.props.variant.image)
            }, 500)
        }
       
    }
    componentDidUpdate(prevProps) {

        // console.log("component did update<<<<<<<<<<"+JSON.stringify(this.props.priceConfig)+":prev:"+JSON.stringify(prevProps.priceConfig))
        if(this.props.isActive !== prevProps.isActive) {
            this.setState({
                active: this.props.isActive
            });
        }

        if(this.props.variant !== prevProps.variant) {
            this.setState({
                variant: this.props.variant
            });
            this.setMedia(this.props.variant.image)
        }

        if(this.props.priceConfig !== prevProps.priceConfig) {
            this.setState({
                priceConfig: this.props.priceConfig
            });
        }
    }

    onPriceChange = (value) => {
        const form = this.props.formRef.current.getFieldsValue()

        const maxPrice = form["varPurchasePrice"+this.state.variant.key]
        const profitMargin = form["profit_margin_perc"+this.state.variant.key]
        const loadingFactor = form["loading_factor"+this.state.variant.key]
        const discountPercent = form["discount_perc"+this.state.variant.key]
        const courierCharges = form["courier_charges"+this.state.variant.key]
        const gst = form["gst_perc"+this.state.variant.key].replace("%", "")

        const payCommission = this.state.priceConfig.pay_commission

        console.log("pay<<<<<<"+payCommission)

        let sp = maxPrice + (maxPrice * profitMargin) / 100 + (maxPrice * loadingFactor) / 100
        sp += courierCharges - (sp * discountPercent) / 100 + (sp * gst) / 100 + (sp * payCommission) / 100
        sp = Math.round((sp + Number.EPSILON) * 100) / 100

        const formObj = {}

        formObj["varSellingPrice"+this.state.variant.key] = sp

        this.props.formRef.current.setFieldsValue(formObj)
    }



    handleChange = info => {
        let fileList = [...info.fileList];
    
        // // 1. Limit the number of uploaded files
        // // Only to show two recent uploaded files, and old ones will be replaced by the new
        // fileList = fileList.slice(-2);
    
        // 2. Read from response and show file link
        fileList = fileList.map(file => {
          if (file.response) {
            // Component will show file.url as link
            file.url = baseUrl + "media/"+ file.response.name;
          }
          return file;
        });

        const key = "varPhoto"+this.state.variant.key
        var fieldObject = {};
        fieldObject[key] = fileList[0].url
        this.props.formRef.current.setFieldsValue(fieldObject)
        this.setState({ fileList, previewImage: fileList[0].url});
      };

    setMedia = (media) => {
        const fileList = []
        fileList.push({
            url: baseUrl + "media/"+ media
        })
        const key = "varPhoto"+this.state.variant.key
        var fieldObject = {};
        fieldObject[key] = fileList[0].url
        this.props.formRef.current.setFieldsValue(fieldObject)
        this.setState({ fileList, previewImage: fileList[0].url});
    }  

    handlePreview = (file) => {

        console.log("handle preview<<<<<<<"+file.url+":pre:"+file.preview)
        this.setState({
            previewImage: file.url || file.preview
        })
    }

    beforeUpload = (file) => {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
          showErrorToast('You can only upload JPG/PNG file!');
        }
        const isLt2M = file.size / 1024 / 1024 < 3;
        if (!isLt2M) {
          showErrorToast('Image must smaller than 3MB!');
        }
        return isJpgOrPng && isLt2M;
      }
    onVendorChange = (value) => {
        
        const key = "varVendor"+this.state.variant.key
        var fieldObject = {};
        fieldObject[key] = this.state.vendors[value].name
        this.props.formRef.current.setFieldsValue(fieldObject)
    }  
      
    render() {

        
        const uploadButton = (
            <div>
                {this.state.loading ? <LoadingOutlined /> : <PlusOutlined />}
                <div style={{ marginTop: 8 }}>Upload</div>
            </div>
        );
        const genExtra = () => (
            <Popconfirm
            disabled={!this.state.active}
            title={<div style={{ marginLeft: '20px', marginBottom: '10px', marginTop: '5px', fontSize: '16px'}}>Are you sure you want to remove this variant?</div>}
            placement="bottomRight"
            okText="Yes"
            cancelText="No"
            onConfirm={(event) => {
                event.stopPropagation();
                this.props.onRemoveVariant(this.state.variant.key)
            }}
            onCancel = {(event) => {
                event.stopPropagation();
            }}
            icon={<ExclamationCircleFilled style={{fontSize: '25px'}}/>} 
            > 
            <DeleteOutlined
                className='delete-icon' 
                onClick={event => {
                    // If you don't want click extra trigger collapse, you can prevent this:
                    event.stopPropagation();
                }}
            />
            </Popconfirm>
        );
        const getFile = (e) => {
            console.log('Upload event:', e);
          
            if (Array.isArray(e)) {
              return e;
            }
           return e && e.fileList;
          };

      return   this.state.variant ? (
       
            <Collapse defaultActiveKey={[this.state.variant.key]} style={{marginTop: '5%', width: '100%'}} className="noselect" >
            <Collapse.Panel header={"Variant "+this.state.variant.key} key={this.state.variant.key} extra={this.props.showDelete ? genExtra() : null}>
                <Row gutter={20}>
                    <Col span={12}>
                    <Form.Item
                        name={"varName"+this.state.variant.key}
                        label="Name"
                        style={{margin:0, marginBottom: 20}}
                        rules={[{ required: true, message: 'Please enter name' }]}
                    >
                        <Input placeholder="Enter name"  disabled = {!this.state.active}/>
                    </Form.Item>
                    <Form.Item
                        name={"varVendor"+this.state.variant.key}
                        label="Vendor"
                        
                        rules={[{ required: true, message: 'Please select vendor' }]}
                    >
                        <Select
                            showSearch
                            mode="single"
                            allowClear
                            placeholder="None"
                            optionFilterProp="children"
                            onChange={this.onVendorChange}
                            filterOption={(input, option) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                            disabled = {!this.state.active}
                        >
                            {this.state.vendors.map((object, i) => {
                                return <Option value={i} key={i}>{object.name + "|" + object.email + "|" + (object.company || "-")}</Option>
                            })}
                        </Select>
                    </Form.Item>
                    </Col>
                    <Col span={12}>
                    <Form.Item
                        name={"varDescription"+this.state.variant.key}
                        label="Description"
                    >
                        <Input.TextArea
                        name="desc-text-area"
                        placeholder="Enter description"
                        disabled = {!this.state.active}
                        />
                    </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item
                            name={"varPhoto"+this.state.variant.key}
                            label={
                                <Row className="media-label">
                                    <Col span={8}>Photo</Col>
                                    <Col span={10} offset={6} style={{textAlign: "right"}}>Preferred Size: 512 * 512 px</Col>
                                </Row> 
                            
                            }
                            rules={[{ required: true, message: 'Photo is required' }]}
                            valuePropName="file"
                            getValueFromEvent={getFile}
                        >
                            <ImgCrop rotate  disabled = {!this.state.active}>
                                <Upload
                                    action={baseUrl + "media"}
                                    name="media"
                                    withCredentials={true}
                                    listType="picture-card"
                                    className="avatar-uploader"
                                    fileList={this.state.fileList}
                                    beforeUpload={this.beforeUpload}
                                    onChange={this.handleChange}
                                    onPreview={this.handlePreview}
                                    showUploadList={false}
                                    disabled = {!this.state.active}
                                    maxCount={1}
                                >
                                    {this.state.fileList.length < 1 && uploadButton}
                                    {this.state.fileList.length > 0 && <img alt="example" style={{ height: '100%', width:'auto' }} src={this.state.previewImage} />}
                                </Upload>
                            </ImgCrop>         
                        </Form.Item>
                    </Col>
                    
                </Row>
                <Row gutter={20}>
                        <Col span={6}>
                                <Form.Item
                                    name={"varStock"+this.state.variant.key}
                                    label="Stock"
                                    initialValue={1}
                                    rules={[{ required: true, message: 'Please enter stock' }]}
                                >
                                    <InputNumber type="number" style={{width: '100%'}}  disabled = {!this.state.active}/>
                                </Form.Item>
                            </Col>
                        <Col span={9}>
                                <Form.Item
                                    name={"varPurchasePrice"+this.state.variant.key}
                                    label="Purchase price"
                                    rules={[{ required: true, message: 'Please enter purchase price' }]}
                                >
                                    <InputNumber placeholder="Enter price" type="number" prefix="₹" style={{width: '100%'}} controls={false}  disabled = {!this.state.active} onChange = {this.onPriceChange}/>
                                </Form.Item>
                            </Col>
                            <Col span={9}>
                                <Form.Item

                                    name={"varSellingPrice"+this.state.variant.key}
                                    label="Selling price"
                                    rules={[{ required: true, message: 'Please enter discount'}]}
                                >
                                    <InputNumber placeholder="Enter price" type="number" prefix="₹" style={{width: '100%'}} controls={false}  disabled = {!this.state.active} readOnly={true}/>
                                </Form.Item>
                            </Col>
                    </Row>

                    <PriceInfo formRef={this.props.formRef} formKey={this.state.variant.key} onPriceChange={this.onPriceChange}/>
            </Collapse.Panel>
        </Collapse>
        ) : null;
    }
}

export default Variant;