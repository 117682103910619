import './Products.less';
import React from 'react';
import axios from "axios";
import AddProduct from "./AddProduct"
import ProductSearchSection from "./ProductSearchSection"
import { baseUrl } from "../utils/baseUrl";
import { Spin, Table, Space, Button, Tooltip, Switch, Layout, Popconfirm} from 'antd';
import Highlighter from 'react-highlight-words';
import {
  
    EditOutlined,
    DeleteOutlined,
    StarOutlined,
    CopyOutlined,
    CheckOutlined,
    CloseOutlined,
    ExclamationCircleFilled
  } from '@ant-design/icons';
import { withRouter } from 'react-router-dom';
import {showSuccessToast, showErrorToast, dismissToast} from '../utils/Utils';

class ProductsPage extends React.Component {

    state = {
        products: [],
        searchTerm: '',
        product: null,
        copyProduct: null
    };

    

    componentDidMount() { 
      // console.log("componentDidMount<<<<<<<");
      this.getProducts();
    }

    componentDidUpdate(prevProps) {
        
         if(this.props.current !== prevProps.current && this.props.current === 'Products') {  
            console.log("componentDidUpdate<<<<<<<<<<<"+prevProps.current+":props:"+this.props.current);
            this.getProducts();
         }
    }

    getProducts() {


        this.setState({
           loading: true,
           
        });

        var searchStr = ''
        if(this.state.searchTerm){
          searchStr = "?searchTerm="+this.state.searchTerm
        }
      
        axios
        .get(baseUrl + "product/count"+searchStr, { withCredentials: true })
        .then(response => {
            const total = response.data.count;
            this.setState({
               totalProducts: total,
               pageSize: 10,
               currentPage: 1
            });

            this.getProductsByPage(0, 10);
            // console.log("total111 ="+total);
        })
        .catch(error => {
          console.log("Response", error);
        });

    }

    getProductsByPage = (page, limit) => {


      
      
      this.setState({
        loading: true,
        
     });

     var searchStr = ''
     if(this.state.searchTerm){
      searchStr = "&searchTerm="+this.state.searchTerm
     }
    
      axios
      .get(baseUrl + "product/find?page="+page+"&limit="+limit+searchStr, {withCredentials: true})
      .then(response => {
        const products = response.data;
       

        this.setState({
          loading: false,
          products: products,
    
        });
      })
      .catch(error => {
        console.log("Response22222222", error);
      });
    }

    getProductsByTitle = (searchTerm) => {
      
      
      this.setState({
        searchTerm,
        
      },() => { this.getProducts(); });
      
      
       
    }

    onProductFormClose = () =>
    {
      this.setState({
        addProduct: false,
        copyProduct: null,
        loading: true,
        product: null,
        products: []
      })
      this.getProducts()
    }

    onAddProduct = () =>
    {
       this.setState({
         addProduct: true
       })
    }
    getCategoriesString(categories)
    {
       const categoryStr =  categories ? categories
       .map((item, index) => {
         return item.title;
       })
       .join(", ")
       : '-';

       return <Tooltip placement="topLeft" title = {categoryStr}>
       {categoryStr}
      </Tooltip>
    }
    handleMenuClick(e) {
      
      console.log('click', e);
    }


    onEdit = (product) => {
      this.setState({
        addProduct: true,
        product: product
      })
    }

    onDelete = (product) => {
      this.setState({
        showProductConfirm: true
      })
    }

    onDuplicate = (product) => {
      this.setState({
        addProduct: true,
        copyProduct: product,
      })
    }

    performDelete = (product) => {
      this.setState({
        loading: true,
      })
      axios
        .delete(baseUrl + "product/"+product.id, { withCredentials: true })
        .then(response => {
            this.getProducts()
        })
        .catch(error => {
          showErrorToast('Error deleting product');
        });
    }

    onFeaturedChanged = (product) => {
        
      axios.put(baseUrl + "product/"+product.id, {
         featured: !product.featured
      },
      { withCredentials: true })
      .then(response => {

        product.featured = response.data.code == 0 ? !product.featured : product.featured
        product.changingFeatured = false
      })
      .catch(error => {
        showErrorToast('Error changing featured')
      });
  }

    render() {

   

      const paginationOptions = {
        showSizeChanger: false,
        showQuickJumper: true,
        onShowSizeChange: (_, pageSize) => {
            this.setState({
                pageSize
            });
        },
        onChange: (page) => {
            this.setState({
              currentPage: page
            })
            this.getProductsByPage(page - 1, this.state.pageSize);
        },
        pageSizeOptions: [10, 20, 50, 100],
        showTotal: (total, range) => `${range[0]} to ${range[1]} of ${total}`,
      };
  
      const pagination = {
        ...paginationOptions,
        total: this.state.totalProducts,
        current: this.state.currentPage,
        pageSize: this.state.pageSize,
      };

        const columns = [
            {
              title: 'Title',
              dataIndex: 'title',
              key: 'name',
              ellipsis: {
                showTitle: false,
              },
              render: title => (
                <Tooltip placement="topLeft" title={title}>
                  {this.state.searchTerm ?
                  (
                  <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[this.state.searchTerm]}
                    autoEscape
                    textToHighlight={title ? title.toString() : ''}
                  />
                ) : <span>{title}</span>}
                </Tooltip>
              ),
              width: '22%',
              //  ...this.getColumnSearchProps('title'),
            },
            {
              title: 'Variants',
              dataIndex: 'variants',
              key: 'variants',
              render: variants => (
                <div>{variants ? variants
                    .map((item, index) => {
                      return item.label;
                    })
                    .join(", ")
                    : '-'}</div>
              ),
              width: '10%',
              // ...this.getColumnSearchProps('variants'),
            },
            {
              title: 'Brand',
              dataIndex: 'brand',
              key: 'brand',
              width: '10%',
              // ...this.getColumnSearchProps('address'),
            },
            {
                title: 'Categories',
                dataIndex: 'categories',
                key: 'categories',
                ellipsis: {
                  showTitle: false,
                },
                render: categories => (
                  this.getCategoriesString(categories)
                ),
                width: '20%',
              //  ...this.getColumnSearchProps('categories'),
              },
              {
                title: 'Featured',
                dataIndex: 'featured',
                key: 'featured',
                width: '10%',
                align:'center',
                render: (text, record) =>
                   <Tooltip title="Featured product"> 
                   <Switch
                      loading={record.changingFeatured}
                      checkedChildren={<CheckOutlined />}
                      unCheckedChildren={<CloseOutlined />}
                      onChange={(checked) => {
                        if(record.featured != checked) {
                          record.changingFeatured = true
                          this.onFeaturedChanged(record)
                        }
                      }}
                      defaultChecked={record.featured}
                      size="small"
                    />
                   </Tooltip>
                // ...this.getColumnSearchProps('address'),
              },
              // {
              //   title: 'Cart',
              //   dataIndex: 'cartaddedusers',
              //   key: 'cartaddedusers',
              //   render: cartaddedusers => (
              //   <div>{cartaddedusers.length}</div>
              //   ),
              //   width: '6%',
              // },
              {
                 title: 'Status',
                 dataIndex: 'status',
                 key: 'status',
                 width: '10%',
                 align:'center',
                 render: (text, record) =>
                 <Tooltip title="Activate product"> 
                 <Switch
                    loading={record.changingActive}
                    checkedChildren={<CheckOutlined />}
                    unCheckedChildren={<CloseOutlined />}
                    defaultChecked={record.active}
                    size="small"
                    onChange={(checked) => {
                      if(record.active != checked) {
                        record.changingActive = true
                        this.onActiveChanged(record)
                      }
                    }}
                  />
                 </Tooltip>
              },
              {
                title: 'Options',
                dataIndex: 'options',
                key: 'options',
                align:'center',
                width: '12%',
                render: (text, record) =>
                  <Space>
                    <Tooltip title="Edit product"><Button
                      type="primary"
                      shape="circle"
                      size="small"
                      onClick={() => {
                        this.onEdit(record)
                      }}
                      icon={<EditOutlined />}
                      
                    /></Tooltip>
                    
                    <Tooltip title="Duplicate product"> <Button
                      type="primary"
                      shape="circle"
                      size="small"
                      onClick={() => {
                        this.onDuplicate(record)
                      }}
                      icon={<CopyOutlined />}
                      
                    /></Tooltip>
                      <Popconfirm
                
                        title={<div style={{ marginLeft: '20px', marginBottom: '10px', marginTop: '5px', fontSize: '16px'}}>Are you sure you want to delete?</div>}
                        placement="bottomRight"
                        arrowPointAtCenter={true}
                        okText="Yes"
                        cancelText="No"
                        onConfirm={() => {
                          this.performDelete(record)
                        }}
                        icon={<ExclamationCircleFilled style={{fontSize: '25px'}}/>} 
                    > 
                     <Tooltip title="Delete product">  <Button
                      type="primary"
                      shape="circle"
                      size="small"
                    
                      icon={<DeleteOutlined />}
                      
                    /></Tooltip>
                    </Popconfirm>
          
                    
                  </Space>
              },
          ];
        
        return (
            
          <Layout>
              <ProductSearchSection onSearch = {this.getProductsByTitle} onAddProduct = {this.onAddProduct}/>
              

              {
                this.state.addProduct ? <AddProduct visible={this.state.addProduct} onClose = {this.onProductFormClose} product = {this.state.product} copyProduct = {this.state.copyProduct}/>
                  : null
              }

              <Spin spinning={this.state.loading} size='large'>
                <Table columns={columns} dataSource={this.state.products} pagination={pagination} rowKey="id" bordered/>
              </Spin>

          </Layout>
             
            
        );
    }
}


export default withRouter(ProductsPage);