import React from 'react';
import { Form, Drawer, Button} from 'antd';
import SearchTags from '../products/SearchTags';
import CategoryBasicInfo from './CategoryBasicInfo';
import axios from "axios";
import { baseUrl } from "../utils/baseUrl";
import { showSuccessToast, showErrorToast, dismissToast } from "../utils/Utils";

class AddCategory extends React.Component {
    state = {
        visible: false,
        loading: false,
        category: null
    };
    formRef = React.createRef();
    categoryBasicInfoRef = React.createRef();
    searchTagInfoRef = React.createRef();

    onClose = () => {
        
        this.categoryBasicInfoRef.current.setFormActive(true);
        this.searchTagInfoRef.current.setFormActive(true);
        this.formRef.current.resetFields();
        this.searchTagInfoRef.current.resetFields();
        this.setState({
            loading: false
        })
        this.props.onClose()
    }
    componentDidMount() { 

        this.setState({
            visible: this.props.visible
        })
       
        dismissToast()

        if(this.props.category) {


            this.setState({
                category: this.props.category
            })

            const categoryFormObj = {}
            categoryFormObj.title = this.props.category.title
           
            categoryFormObj.alias = this.props.category.alias
            categoryFormObj.featured = this.props.category.featured
            categoryFormObj.active = this.props.category.active
            categoryFormObj.rank = this.props.category.rank

            setTimeout(() => { 
                this.searchTagInfoRef.current.setTags(this.props.category.searchTags)
                this.categoryBasicInfoRef.current.setParentId(this.props.category.parentId)
                this.formRef.current.setFieldsValue({
                    ...categoryFormObj
                })
                
            }, 500)
        }
        if(this.props.copyCategory) {
            setTimeout(() => {
                this.searchTagInfoRef.current.setTags(this.props.copyCategory.searchTags)
                this.categoryBasicInfoRef.current.setParentId(this.props.copyCategory.parentId)
                this.formRef.current.setFieldsValue({
                    ...this.props.copyCategory
                })
            }, 500)
        }
        // this.formRef.current.resetFields();
    }

    onSubmit = () => {
        // this.categoryBasicInfo.validate();
        
        // console.log("on subm<<<<<<<<<<<<<"+this.formRef.current)
        this.formRef.current.validateFields().then(values => {
            

            this.categoryBasicInfoRef.current.setFormActive(false)
            this.searchTagInfoRef.current.setFormActive(false)

            const tags = this.searchTagInfoRef.current.getTags()
            const parentId = this.categoryBasicInfoRef.current.getParentId()

            console.log("on subm<<<<<<<<<<<values<<"+JSON.stringify(values)+":cc:"+ tags)
            
            this.setState({
                loading: true,
            })
            values.tags = tags;
            values.parent_id = parentId;

            this.createCategory(values)

        }).catch(errorInfo => {
            //console.log("on errorInfo<<<<<<<<<<<values<<"+errorInfo)
            // showErrorToast('Error creating category')
            //this.clearValidation();
        });
    }

    async createCategory(data)
    {
        try {
            const url = data.catPhoto
            const dataToPost =  {
                title: data.title,
                alias: data.alias || "",
                description: data.title,
                active: data.active || false,
                featured: data.featured || false,
                rank: data.rank || 1,
                search_tags: data.tags || [],
                parent_id: data.parentId || -1,
                media: [url.substring(url.lastIndexOf('/') + 1)]
            };

            const response = this.state.category ? await axios.put(baseUrl + "category/"+this.state.category.id, dataToPost, {withCredentials: true}) 
            : await axios.post(baseUrl + "category/create", dataToPost, {withCredentials: true});

            console.log("updating cat<<<<<<<<<<<<<"+JSON.stringify(data))

            if (response.data.code == 0) {
                this.setState({ loading: false});
                
                this.onClose()
                showSuccessToast(this.state.category ? 'Category updated successfully' : 'Category created successfully')
               
            } else {
                this.categoryBasicInfoRef.current.setFormActive(true);
                this.searchTagInfoRef.current.setFormActive(true);
                this.setState({ loading: false});
                let errorMsg = response.data.code == 1001 ? 'Category with this title already exist' : this.state.category ? 'Error updating category' : 'Error creating category'
                showErrorToast(errorMsg)
               
            }
        } catch (err) {
            console.log("Response", err);
            this.setState({ loading: false});
            showErrorToast(this.state.category ? 'Error updating category' : 'Error creating category')
        }
    }

    

    clearValidation = () => {
        const timer = setTimeout(() => {
        
          this.formRef.current.resetFields();
  
        }, 3000);
        return () => clearTimeout(timer);
      }
    
    onFinish = () => {
        console.log("on finishM<MMMMMMM<<<<<<<<<<<<")
    }
    
  

    render() {
        return <Drawer
            title= {this.state.category ? "Edit Category" : "Add Category"}
            width={800}
            
            onClose={this.onClose}
            visible={this.state.visible}
            headerStyle = {{backgroundColor: '#ae543a', color: 'white'}}
            bodyStyle={{ backgroundColor: '#f0f2f5', alignItems: 'center', justifyContent: 'center'}}
            footer={
            <div
                style={{
                textAlign: 'right',
                }}
            >
                <Button onClick={this.onClose} style={{ marginRight: 8 }}>
                Cancel
                </Button>
                <Button onClick={this.onSubmit} type="primary" htmlType="submit" loading = {this.state.loading}>
                {
                    this.state.loading ? (this.state.category ? "Updating" : "Creating") :  (this.state.category ? "Update" : "Create")
                }
                </Button>
            </div>
            }
        >
           
            <Form layout="vertical"
                onFinish={this.onFinish}
                ref={this.formRef}
                requiredMark="true"
            >
                <CategoryBasicInfo ref={this.categoryBasicInfoRef} formRef = {this.formRef} category={this.state.category}/>
                <SearchTags ref={this.searchTagInfoRef}/>
            </Form>
           
           
      </Drawer>
    }
}


export default (AddCategory);