import './Products.less';
import React from 'react';
import { Card, Col, Form, InputNumber, Radio, Row } from 'antd';
class PriceInfo extends React.Component
{
    state = {
        active: true,
        key: null
    }

    componentDidMount()
    {
        // console.log("key==="+JSON.stringify(this.props.formRef.current.values))

        this.setState({
            key: this.props.formKey
        })
    }


    onChange = (value) => {
        console.log('changed', value);

        this.props.onPriceChange()
    }

    setFormActive = (active) =>
    {
        this.setState({
          active: active
        })
    }

    render()
    {
        return this.state.key ? (
            <>
                <Row gutter={20}>
                        <Col span={6}>
                                <Form.Item
                                    name={"profit_margin_perc"+this.state.key}
                                    label="Profit Margin"
                                >
                                    <InputNumber 
                                        style={{width: '100%'}}  
                                        disabled = {!this.state.active}       
                                        initialvalue={0}
                                        min={0}
                                        max={100}
                                        onChange={this.onChange}
                                        formatter={value => `${value}%`}
                                        parser={value => value.replace('%', '')}
                                    />
                                </Form.Item>
                            </Col>
                        <Col span={6}>
                                <Form.Item
                                    name={"loading_factor"+this.state.key}
                                    label="Loading factor"
                                >
                                    <InputNumber 
                                        style={{width: '100%'}}  
                                        disabled = {!this.state.active}       
                                        initialvalue={0}
                                        min={0}
                                        max={100}
                                        onChange={this.onChange}
                                        formatter={value => `${value}%`}
                                        parser={value => value.replace('%', '')}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item
                                    name={"discount_perc"+this.state.key}
                                    label="Discount percent"
                                >
                                     <InputNumber 
                                        style={{width: '100%'}}  
                                        disabled = {!this.state.active}       
                                        initialvalue={0}
                                        min={0}
                                        max={100}
                                        onChange={this.onChange}
                                        formatter={value => `${value}%`}
                                        parser={value => value.replace('%', '')}
                                    />
                                </Form.Item>
                            </Col>
                            
                          
                            <Col span={6}>
                                <Form.Item
                                    name={"courier_charges"+this.state.key}
                                    label="Courier charges"
                                >
                                     <InputNumber 
                                        style={{width: '100%'}}  
                                        onChange={this.onChange}
                                        disabled = {!this.state.active}       
                                        initialvalue={0}
                                        min={0}
                                    />
                                </Form.Item>
                            </Col>
                </Row>
                <Row>
                    <Col span={12}>
                        <Form.Item
                            name={"gst_perc"+this.state.key}
                            label="GST"
                        >
                            <Radio.Group value="5%" size='medium' onChange={this.onChange}>
                                <Radio.Button value="0%">0%</Radio.Button>
                                <Radio.Button value="5%">5%</Radio.Button>
                                <Radio.Button value="12%">12%</Radio.Button>
                                <Radio.Button value="18%">18%</Radio.Button>
                                <Radio.Button value="28%">28%</Radio.Button>
                            </Radio.Group>
                        </Form.Item>
                    </Col>
                </Row>
            </>
        ) : null
    }
}

export default PriceInfo;