
import './Products.less';
import React from 'react';
import { Row, Col, Card, Button} from 'antd';
import { PlusOutlined} from '@ant-design/icons';
import Variant from './Variant';
import axios from "axios";
import { baseUrl } from "../utils/baseUrl";
class VariantInfo extends React.Component {
    state = {
        active: true,
        loading: false,
        vendors: [],
        variants: [],
        priceConfig: null,
        showDelete: false,
    };

    componentDidMount()
    {
        console.log("vi did mount<<<<<<<<<"+JSON.stringify(this.props.priceConfig))
        this.setState({
            priceConfig: this.props.priceConfig
        })
        this.getVendors()
    }

   

    getVendors = () => {
        axios
        .get(baseUrl + "vendor/find?page=-1&limit=-1", { withCredentials: true })
        .then(response => {
            const vendors = response.data || [];

            const variants = this.state.variants;

           

            if(variants.length == 0) {

                const index = variants.length + 1
                const priceConfig = this.state.priceConfig;

                const variantObj = {
                    key: index
                }

                const variantFormObj = {}

                variantFormObj["profit_margin_perc"+index] = priceConfig.profit_margin_perc
                variantFormObj["loading_factor"+index] = priceConfig.loading_factor
                variantFormObj["discount_perc"+index] = priceConfig.discount_perc
                variantFormObj["courier_charges"+index] = priceConfig.courier_charges
                variantFormObj["gst_perc"+index] = priceConfig.gst_perc + "%"

                this.props.formRef.current.setFieldsValue({
                    ...variantFormObj
                })
                console.log("in pc <<<<<<<<<"+this.props.formRef.current)

                //this.props.formRef.current.getFieldDecorator("profit_margin_perc"+index, { initialValue: priceConfig.profit_margin_perc })

                variants.push(variantObj)
            }
           
       
            this.setState({
                vendors: vendors,
                variants
            });
           
        })
        .catch(error => {
          console.log("Response", error);
        });
    }

    onChange = (value) => {
        console.log(`selected ${value}>>>>>id`+this.state);

        // if(this.state.categories) {
        //    this.setState({
        //     parentId: this.state.categories[value].id
        //    })
        // }
    }
    setFormActive = (active) =>
    {
        this.setState({
          active: active
        })
    }

    getVariants = () => {
        return this.state.variants
    }

    onNewVariant = () =>
    {
        const variants = this.state.variants;
        const priceConfig = this.state.priceConfig;
        const index = variants.length + 1

        const variantObj = {
            key: index
        }

        const variantFormObj = {}

        variantFormObj["profit_margin_perc"+index] = priceConfig.profit_margin_perc
        variantFormObj["loading_factor"+index] = priceConfig.loading_factor
        variantFormObj["discount_perc"+index] = priceConfig.discount_perc
        variantFormObj["courier_charges"+index] = priceConfig.courier_charges
        variantFormObj["gst_perc"+index] = priceConfig.gst_perc + "%"

        this.props.formRef.current.setFieldsValue({
            ...variantFormObj
        })
        variants.push(variantObj)
        let showDelete = variants.length > 1
        this.setState({
            variants,
            showDelete
        })

        console.log("on new variante<<<<<<<<<<"+this.state.variants.length)
    }

    resetFields = () => {

    }

    onRemoveVariant = (key) =>
    {
        // console.log("in delet>>>>>>"+key)
        const variants = this.state.variants;
        variants.splice(key - 1, 1)

        let showDelete = variants.length > 1
        

        this.setState({
            variants,
            showDelete
        })

        
    }
    setVariants = (variants) => {
        
        const localVariants = []

        variants.forEach((variant, index) => {
            console.log("in variants<<<<<<<<<<"+index)
            localVariants.push({
                key: index + 1,
                image: variant.image
            })
        })

      

        this.setState({
            variants: localVariants
        })
    }

    onSearch(val) {
        console.log('search:', val);
      }

    render() {

       
        return (
            <Card title="Variants" style={{marginTop: '5%'}}>
            <Row gutter={16}>
                <Col span={24}>
                <Button type="primary" onClick={this.onNewVariant}  disabled = {!this.state.active}>
                    <PlusOutlined /> New Variant
                </Button>
                </Col>
            </Row>    
            
            {
                this.state.priceConfig ?
                this.state.variants.map((variant, index) => {
                  return <Variant key={index} 
                  variant={variant} 
                  onRemoveVariant = {this.onRemoveVariant} 
                  vendors = {this.state.vendors} 
                  showDelete = {this.state.showDelete} 
                  formRef={this.props.formRef} 
                  priceConfig={this.state.priceConfig}
                  isActive = {this.state.active}/>
                }) : null
            }
           

    
            </Card>
            
       
        
        
       );
    }
}
export default VariantInfo;