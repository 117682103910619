
import './Products.less';
import React from 'react';
import { Form, Drawer, Button} from 'antd';
import VariantInfo from './VariantInfo'
import SearchTags from './SearchTags'
import BasicInfo from './BasicInfo'
import axios from "axios";
import { baseUrl } from "../utils/baseUrl";
import { showSuccessToast, showErrorToast, dismissToast } from "../utils/Utils";
import RecommendProduct from './RecommendProduct';

class AddProduct extends React.Component {

    state = {
        visible: false,
        loading: false,
        product: null,
        priceConfig: null
    };
    formRef = React.createRef();
    basicInfoRef = React.createRef();
    searchTagInfoRef = React.createRef();
    variantInfoRef = React.createRef();
    recommendProductsRef = React.createRef();

    componentDidMount() { 

        this.setState({
            visible: this.props.visible
        })

        dismissToast()

        // console.log("component did ouht<<<<<<<<<<<"+this.props.product)

        this.getDefaultPriceConfig()

        if(this.props.product) {
          this.getProductInfo(this.props.product.id)
        }
    }

    getProductInfo = (productId) => {
      axios
      .get(baseUrl + "product/"+productId, { withCredentials: true })
      .then(response => {
        const product = response.data;
        console.log("product=="+JSON.stringify(product))
        if(product) {
           this.setState({
            product
           })
           setTimeout(() => { 
            this.setupProduct(product)
             
         }, 500)
        }
      })
      .catch(error => {
        console.log("Response", error);
      });
    }

    getDefaultPriceConfig = () => {
        axios
        .get(baseUrl + "settings/price-config", { withCredentials: true })
        .then(response => {
            const config = response.data || {};
            if(this.props.product) {


              this.setState({
                product: this.props.product,
                priceConfig: config
              })
  
              setTimeout(() => { 
                 this.setupProduct(this.props.product)
                  
              }, 500)
            }

            this.setState({
              priceConfig: config
            })
            
            if(this.props.copyProduct) {
             
                setTimeout(() => {
              
                  this.setupProduct(this.props.copyProduct)
                }, 500)
            }
          
          
        })
        .catch(error => {
          console.log("Response", error);
        });
    }

    setupProduct(product)
    {
     
      this.searchTagInfoRef.current.setTags(product.searchTags)
      // this.formRef.current.setFieldsValue({
      //     ...this.props.product
      // })
      const variants = product.variants;
      const variantFormObj = {}
      console.log("in setup product<<<<<<<<<"+JSON.stringify(product))

      for(let i = 0; i < variants.length; i++) {
       
        const variant = variants[i]
        const index = i + 1
        variantFormObj["varName"+index] = variant.label
        variantFormObj["varStock"+index] = variant.stock
        variantFormObj["varPurchasePrice"+index] = variant.purchase_price
        variantFormObj["varSellingPrice"+index] = variant.selling_price
        variantFormObj["varVendor"+index] = variant.vendor
        variantFormObj["profit_margin_perc"+index] = variant.profit_margin_perc
        variantFormObj["loading_factor"+index] = variant.loading_factor
        variantFormObj["discount_perc"+index] = variant.discount_perc
        variantFormObj["courier_charges"+index] = variant.courier_charges
        variantFormObj["gst_perc"+index] = variant.gst_perc + "%"
        
      }

      variantFormObj.title = product.title
      variantFormObj.description = product.description
      variantFormObj.brand = product.brand
      variantFormObj.featured = product.featured
      variantFormObj.active = product.active
      variantFormObj.category = []
      product.categories.forEach(category => {
        variantFormObj.category.push(category.id)
      });


      this.formRef.current.setFieldsValue({
          ...variantFormObj
      })

      this.variantInfoRef.current.setVariants(variants)

      this.basicInfoRef.current.setCatChecked(variantFormObj.category)
    }

    componentDidUpdate(prevProps) {

        // console.log("component did update<<<<<<<<<<"+this.props.visible+":prev:"+prevProps.visible)
        if(this.props.visible !== prevProps.visible) {
            this.setState({
                visible: this.props.visible
            });
        }
    }

    onClose = () => {
        this.basicInfoRef.current.setFormActive(true);
        this.variantInfoRef.current.setFormActive(true);
        this.searchTagInfoRef.current.setFormActive(true);
        this.recommendProductsRef.current.setFormActive(true);
        this.formRef.current.resetFields();
        this.searchTagInfoRef.current.resetFields();
        this.recommendProductsRef.current.resetFields();
        this.basicInfoRef.current.resetFields();
        this.variantInfoRef.current.resetFields();
        this.setState({
            loading: false,
            product: null
        })
        this.props.onClose()
    }

    onSubmit = () => {
      // this.categoryBasicInfo.validate();
      
      // console.log("on subm<<<<<<<<<<<<<"+this.formRef.current)
      this.formRef.current.validateFields().then(values => {
          

          const variants = this.variantInfoRef.current.getVariants()

          // console.log("get varians<<<<<<<<"+variants.length)

          if(variants.length === 0)
          {
            showErrorToast("Add atleast one variant")
            return
          }

          this.basicInfoRef.current.setFormActive(false)
          this.variantInfoRef.current.setFormActive(false)
          this.searchTagInfoRef.current.setFormActive(false)

          this.recommendProductsRef.current.setFormActive(false)

          const tags = this.searchTagInfoRef.current.getTags()


          const recommended_products = this.recommendProductsRef.current.getRecommendedProducts()

          const product_ids_recommend = []
          recommended_products.forEach(product => {
            product_ids_recommend.push(product.id)
          })
          // console.log("on subm<<<<<<<<<<<values<<"+JSON.stringify(values)+":cc:"+ tags)
          
          this.setState({
              loading: true,
          })
          values.tags = tags;


          const variantsObj = []

          for(let i = 0; i < variants.length; i++) {
              const variantObj = {}
              const index = i + 1
              variantObj.label = values["varName"+index]
              variantObj.stock = values["varStock"+index]
              variantObj.purchase_price = values["varPurchasePrice"+index]
              const url = values["varPhoto"+index]
              variantObj.image = url.substring(url.lastIndexOf('/') + 1)
              variantObj.vendor = values["varVendor"+index]
              variantObj.selling_price = values["varSellingPrice"+index]

              variantObj.profit_margin_perc = values["profit_margin_perc"+index]
              variantObj.loading_factor = values["loading_factor"+index]
              variantObj.discount_perc = values["discount_perc"+index]
              variantObj.courier_charges = values["courier_charges"+index]
              variantObj.gst_perc = values["gst_perc"+index].replace("%", "")
              variantsObj.push(variantObj)
          }
          
          values.variants = variantsObj
          this.createProduct(values, product_ids_recommend)

          

      }).catch(errorInfo => {
          console.log("on errorInfo<<<<<<<<<<<values<<"+errorInfo)
          // showErrorToast('Error adding product')
          //this.clearValidation();
      });
  }

   async recommendProducts(ids, product_id)
   {
      try {
         const productId = product_id
         const dataToPost =  {
          productIds: ids
         }
         const response = await axios.post(baseUrl + "product/recommend/"+product_id, dataToPost, {withCredentials: true});
         if (response.data.code === 0) {
         }
         else {
            this.setState({ loading: false});
            showErrorToast('Error recommending product')
         }
      }
      catch (err) {
        console.log("Response", err);
        this.setState({ loading: false});
        showErrorToast('Error recommending product')
      }
   }

   async createProduct(data, recommended_products)
   {
      try {
        const dataToPost =  {
           title: data.title,
           description: data.description,
           brand: data.brand,
           featured: data.featured,
           active: data.active,
           rank: data.rank,
           categories: data.category,
           variant: data.variants,
           searchTags: data.tags,
        }
        const response = this.state.product ? await axios.put(baseUrl + "product/"+this.state.product.id, dataToPost, {withCredentials: true}) 
        : await axios.post(baseUrl + "product/create", dataToPost, {withCredentials: true});

        //console.log("updating cat<<<<<<<<<<<<<"+JSON.stringify(data))

        if (response.data.code === 0) {

            await this.recommendProducts(recommended_products, response.data.id)
            this.setState({ loading: false});
            

            
            this.onClose()
            showSuccessToast(this.state.product ? 'Product updated successfully' : 'Product created successfully')
           
        } else {
            this.basicInfoRef.current.setFormActive(true);
            this.searchTagInfoRef.current.setFormActive(true);
            this.variantInfoRef.current.setFormActive(true);
            this.setState({ loading: false});
            let errorMsg = response.data.code === 1001 ? 'Product with this title already exist' : this.state.product ? 'Error updating product' : 'Error creating product'
            showErrorToast(errorMsg)
           
        }
      }
      catch (err) {
        console.log("Response", err);
        this.setState({ loading: false});
        showErrorToast(this.state.product ? 'Error updating product' : 'Error creating product')
      }
   }

    render() {
        return <Drawer
        title={this.state.product ? "Edit Product" : "Add Product"}
        width={800}
        
        onClose={this.onClose}
        visible={this.state.visible}
        headerStyle = {{backgroundColor: '#ae543a', color: 'white'}}
        bodyStyle={{ backgroundColor: '#f0f2f5' }}
        footer={
          <div
            style={{
              textAlign: 'right',
            }}
          >
            <Button onClick={this.onClose} style={{ marginRight: 8 }}>
              Cancel
            </Button>
             <Button onClick={this.onSubmit} type="primary" htmlType="submit" loading = {this.state.loading}>
                {
                    this.state.loading ? (this.state.product ? "Updating" : "Creating") :  (this.state.product ? "Update" : "Create")
                }
              </Button>
          </div>
        }
      >
        <Form layout="vertical" ref={this.formRef}>
       
        <BasicInfo ref={this.basicInfoRef} formRef={this.formRef}/>
         
        <RecommendProduct product={this.state.product} ref={this.recommendProductsRef}/> 
       
        <SearchTags ref={this.searchTagInfoRef}/>
        {this.state.priceConfig ? (<VariantInfo ref={this.variantInfoRef} formRef={this.formRef} priceConfig={this.state.priceConfig}/>) : null}
        
       
        </Form>
      </Drawer>
    }
}

export default (AddProduct);