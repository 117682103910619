import './App.less';
import HomePage from './components/HomePage';
import React, { Component } from 'react';
import { Route, HashRouter } from 'react-router-dom';
import AuthGuard from './components/account/AuthGuard';
import { notification } from 'antd';
const baseUrl = process.env.PUBLIC_URL;

class App extends Component  {

  componentDidMount()
  {
    notification.config({
      placement: 'bottomRight',
      bottom: 50,
      
    });
  }

  render() {
    return (
      <HashRouter>
        <div className="App">
          <Route
                exact
                path={baseUrl + "/"}
                render={props => (
                  <AuthGuard component={HomePage} props={props}/>
                )}
              />
        </div>
      </HashRouter>
    );
  }
}

export default App;