import React from "react";
import { Form, Row, Col, Input, Switch, Card, InputNumber, Select} from 'antd';
import {CheckOutlined, CloseOutlined} from '@ant-design/icons';
class VendorExtraInfo extends React.Component {
    state = {
        active: true
    }
    setFormActive = (active) =>
    {
        this.setState({
          active: active
        })
    }
    render()
    {
        return (
        <Card title="Additional Information" style={{marginTop: 30}}>
            <Row gutter={16}>
              <Col span={24}>
                <Form.Item
                  name="address"
                  label="Address"
                >
                  <Input.TextArea  placeholder="Enter address" disabled = {!this.state.active} name="text-area-addr"/>
                </Form.Item>
              </Col>
              
            </Row>
            <Row gutter={16}>
                <Col span={12}>
                  <Form.Item
                    name="gst"
                    label="GST no"
                  >
                    <Input placeholder="Enter GST no" disabled = {!this.state.active} maxLength={15}/>
                  </Form.Item>
                </Col>
                <Col span = {6}>
                <Form.Item
                  name="active"
                  label="Activate Vendor"
                  valuePropName="checked"
                  initialValue={true}
                
                  >
                <Switch checkedChildren={<CheckOutlined />}
                  unCheckedChildren={<CloseOutlined />} disabled = {!this.state.active}/>
                </Form.Item>
                  
              
                </Col>
                <Col span={6}>
                  <Form.Item
                      name="rank"
                      label="Rank"
                      initialValue={1}
                      rules={[{ required: true, message: 'Please set rank' }]}
                  >
                      <InputNumber type="number" disabled = {!this.state.active}/>
                  </Form.Item>
              </Col>
            </Row>
        </Card>
        )
    }
}

export default VendorExtraInfo;