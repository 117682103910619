import React from 'react';
import { Route } from 'react-router-dom';
import LoginPage from './LoginPage';
import axios from 'axios';
import { baseUrl } from "../utils/baseUrl";

const AuthGuard = ({ component: Component,  props,  ...rest }) => {
  if (window.localStorage.getItem("username")) {
    // axios.defaults.headers.common[
    //   "Authorization"
    // ] = window.localStorage.getItem("token");

    axios
      .get(baseUrl + "auth/checkexpiry", {withCredentials: true})
      .then(response => {

      })
      .catch(error => {
        window.localStorage.clear();
        props.history.push('/');
      });
  }
    return (
      <Route
      {...rest}
      render={props =>
        localStorage.getItem("username") !== null ? (
          <Component {...props} />
        ) : (
          <LoginPage />
        )
      }
  />
     
    );
}

export default AuthGuard