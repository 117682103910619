
import React from 'react';
import { Spin, Table, Space, Button, Tooltip, Switch, Layout, Popconfirm} from 'antd';
import Highlighter from 'react-highlight-words';
import axios from "axios";
import { baseUrl } from "../utils/baseUrl";
import {
  
    EditOutlined,
    DeleteOutlined,
    StarOutlined,
    CopyOutlined,
    CheckOutlined,
    CloseOutlined,
    ExclamationCircleFilled,
  } from '@ant-design/icons';
import AddCategory from './AddCategory';
import CategorySearchSection from './CategorySearchSection';
import { withRouter } from 'react-router-dom';
import {showSuccessToast, showErrorToast, dismissToast} from '../utils/Utils';

class CategoriesPage extends React.Component {

    state = {
        categories: [],
        category: null,
        copyCategory: null,
        searchTerm: '',
    };
    componentDidMount()
    {
        this.getCategories();
      
    }

    onCategoryFormClose = () =>
    {
      this.setState({
        addCategory: false,
        loading: true,
        category: null,
        categories: []
      })

      this.getCategories()
    }

    getCategoriesByTitle = (searchTerm) => {
      
        this.setState({
          searchTerm,
          
        },() => { this.getCategories(); });
        
    }

    getCategories() {
        this.setState({
            loading: true,
            
         });
         var searchStr = ''
        if(this.state.searchTerm){
          searchStr = "?searchTerm="+this.state.searchTerm
        }
      
        axios
        .get(baseUrl + "category/count"+searchStr, { withCredentials: true })
        .then(response => {
            const total = response.data.count;
            this.setState({
               totalCategories: total,
               pageSize: 10,
               currentPage: 1
            });

            this.getCategoriesByPage(0, 10);
            // console.log("total111 ="+total);
        })
        .catch(error => {
          showErrorToast('Error getting categories')
        });
    }

    onAddCategory = () =>
    {
       this.setState({
         addCategory: true
       })
    }

    getCategoriesByPage = (page, limit) => {
        this.setState({
            loading: true,
            
         });
    
         var searchStr = ''
         if(this.state.searchTerm){
          searchStr = "&searchTerm="+this.state.searchTerm
         }
        
          axios
          .get(baseUrl + "category/find?page="+page+"&limit="+limit+searchStr, { withCredentials: true })
          .then(response => {
            const categories = response.data;
            this.getSubcategories(categories)
          })
          .catch(error => {
            showErrorToast('Error getting categories')
          });
    }

    async getSubcategories(categories)
    {
       await Promise.all(categories.map(async (category) => {
        await axios.get(baseUrl + "category/sub/"+category.id, {withCredentials: true}).then(response => {
              const subCategories = response.data;
              let subCategoriesStr = ""
              subCategories.map((subcategory) => {
                subCategoriesStr += subcategory.title + ","
              })
              category.changingFeatured = false
              category.changingActive = false
              category.subCategories = subCategoriesStr.length > 0 ? subCategoriesStr.substring(0, subCategoriesStr.length - 1) : "-"
          })
          .catch(error => {
            category.subCategories = "-"
          });

      }))

      

      this.setState({
        loading: false,
        categories: categories,

      });

    }

    getCatsByTitle = (searchTerm) => {
      
      
      this.setState({
        searchTerm,
        
      },() => { this.getCategories(); });
      
   
    }

    onFeaturedChanged = (category) => {
        
        axios.put(baseUrl + "category/"+category.id, {
           featured: !category.featured
        },
        { withCredentials: true })
        .then(response => {

          category.featured = response.data.code == 0 ? !category.featured : category.featured
          category.changingFeatured = false
        })
        .catch(error => {
          showErrorToast('Error changing featured')
        });
    }

    onActiveChanged = (category) => {
      axios.put(baseUrl + "category/"+category.id, {
        active: !category.active
     },
     { withCredentials: true })
     .then(response => {

       category.active = response.data.code == 0 ? !category.active : category.active
       category.changingActive = false
     })
     .catch(error => {
      showErrorToast('Error changing status')
     });
    }

    onEdit = (category) => {
      this.setState({
        addCategory: true,
        category: category
      })
    }

    onDelete = (category) => {
      this.setState({
        showDeleteConfirm: true
      })
    }

    onDuplicate = (category) => {
      this.setState({
        addCategory: true,
        copyCategory: category,
      })
    }

    performDelete = (category) => {
      this.setState({
        loading: true,
      })
      axios
        .delete(baseUrl + "category/"+category.id, { withCredentials: true })
        .then(response => {
            this.getCategories()
        })
        .catch(error => {
          showErrorToast('Error deleting category');
        });
    }

    render() {
     const paginationOptions = {
        showSizeChanger: false,
        showQuickJumper: true,
        onShowSizeChange: (_, pageSize) => {
            this.setState({
                pageSize
            });
        },
        onChange: (page) => {
            console.log("in page<<<<<<<<<"+page)
            this.setState({
              currentPage: page
            })
            this.getCategoriesByPage(page - 1, this.state.pageSize);
        },
        pageSizeOptions: [10, 20, 50, 100],
        showTotal: (total, range) => `${range[0]} to ${range[1]} of ${total}`,
      };
  
      const pagination = {
        ...paginationOptions,
        total: this.state.totalCategories,
        current: this.state.currentPage,
        pageSize: this.state.pageSize,
      };

        const columns = [
            {
              title: 'Title',
              dataIndex: 'title',
              key: 'name',
              ellipsis: {
                showTitle: false,
              },
              render: title => (
                <Tooltip placement="topLeft" title={title}>
                  {this.state.searchTerm ?
                  (
                  <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[this.state.searchTerm]}
                    autoEscape
                    textToHighlight={title ? title.toString() : ''}
                  />
                ) : <span>{title}</span>}
                </Tooltip>
              ),
              width: '32%',
              //  ...this.getColumnSearchProps('title'),
            },
            {
              title: 'Sub categories',
              dataIndex: 'subCategories',
              key: 'subCategories',
              render: subCategories => (
                <div>{subCategories}</div>
              ),
              width: '32%',
              // ...this.getColumnSearchProps('variants'),
            },
            {
              title: 'Featured',
              dataIndex: 'featured',
              key: 'featured',
              width: '10%',
              align:'center',
              render: (text, record) =>
                 <Tooltip title="Featured category"> 
                 <Switch
                    loading={record.changingFeatured}
                    checkedChildren={<CheckOutlined />}
                    unCheckedChildren={<CloseOutlined />}
                    onChange={(checked) => {
                      if(record.featured != checked) {
                        record.changingFeatured = true
                        this.onFeaturedChanged(record)
                      }
                    }}
                    defaultChecked={record.featured}
                    size="small"
                  />
                 </Tooltip>
              // ...this.getColumnSearchProps('address'),
            },
            {
                 title: 'Status',
                 dataIndex: 'status',
                 key: 'status',
                 width: '10%',
                 align:'center',
                 render: (text, record) =>
                 <Tooltip title="Activate category"> 
                 <Switch
                    loading={record.changingActive}
                    checkedChildren={<CheckOutlined />}
                    unCheckedChildren={<CloseOutlined />}
                    onChange={(checked) => {
                      if(record.active != checked) {
                        record.changingActive = true
                        this.onActiveChanged(record)
                      }
                    }}
                    defaultChecked={record.active}
                    size="small"
                  />
                 </Tooltip>
              },
              {
                title: 'Options',
                dataIndex: 'options',
                key: 'options',
                align:'center',
                width: '16%',
                render: (text, record) =>
                  <Space>
                    <Tooltip title="Edit category"><Button
                      type="primary"
                      shape="circle"
                      size="small"
                      onClick={() => {
                        this.onEdit(record)
                      }}
                      icon={<EditOutlined />}
                      
                    /></Tooltip>
                                        
                    <Tooltip title="Duplicate category"> <Button
                      type="primary"
                      shape="circle"
                      size="small"
                      onClick={() => {
                        this.onDuplicate(record)
                      }}
                      icon={<CopyOutlined />}
                      
                    /></Tooltip>
                      <Popconfirm
                
                        title={<div style={{ marginLeft: '20px', marginBottom: '10px', marginTop: '5px', fontSize: '16px'}}>Are you sure you want to delete?</div>}
                        placement="bottomRight"
                        arrowPointAtCenter={true}
                        okText="Yes"
                        cancelText="No"
                        onConfirm={() => {
                          this.performDelete(record)
                        }}
                        icon={<ExclamationCircleFilled style={{fontSize: '25px'}}/>} 
                    > 
                     <Tooltip title="Delete category">  
                     
                          <Button
                            type="primary"
                            shape="circle"
                            size="small"
                            onClick={() => {
                              this.onDelete(record)
                            }}
                            icon={<DeleteOutlined />}
                            
                          />
                    </Tooltip>
                    </Popconfirm>
                     {/* <Tooltip title="Feature category">  <Button
                      type="primary"
                      shape="circle"
                      size="small"
                      icon={<StarOutlined />}
                      
                    /></Tooltip> */} 
                    
                  </Space>
              },
          ];
        
        return (
            
          <Layout>
              <CategorySearchSection onSearch = {this.getCatsByTitle} onAddCategory = {this.onAddCategory}/>
              
              {
                this.state.addCategory ? <AddCategory visible={this.state.addCategory} onClose = {this.onCategoryFormClose} category = {this.state.category} copyCategory = {this.state.copyCategory}/>
                  : null
              }
              
              <Spin spinning={this.state.loading} size='large'>
                <Table columns={columns} dataSource={this.state.categories} pagination={pagination} rowKey="id" bordered/>
              </Spin>

          </Layout>
             
            
        );
    }
}


export default withRouter(CategoriesPage);