import React from 'react';
import { Form, Drawer, Button} from 'antd';
import axios from "axios";
import { baseUrl } from "../utils/baseUrl";
import { showSuccessToast, showErrorToast, dismissToast } from "../utils/Utils";
import VendorBasicInfo from './VendorBasicInfo';
import VendorExtraInfo from './VendorExtraInfo';

class AddVendor extends React.Component {
    state = {
        visible: false,
        loading: false,
        vendor: null
    };
    formRef = React.createRef();
    vendorBasicInfoRef = React.createRef();
    vendorExtraInfoRef = React.createRef();

    onClose = () => {
        
        this.vendorBasicInfoRef.current.setFormActive(true);
        this.vendorExtraInfoRef.current.setFormActive(true);
        this.formRef.current.resetFields();
        this.setState({
            loading: false
        })
        this.props.onClose()
    }
    componentDidMount() { 

        this.setState({
            visible: this.props.visible
        })
       
        dismissToast()

        if(this.props.vendor) {


            this.setState({
                vendor: this.props.vendor
            })

            setTimeout(() => { 
                this.formRef.current.setFieldsValue({
                    ...this.props.vendor
                })
                
            }, 500)
        }
        if(this.props.copyVendor) {
            setTimeout(() => {
                this.formRef.current.setFieldsValue({
                    ...this.props.copyVendor
                })
            }, 500)
        }
        // this.formRef.current.resetFields();
    }

    onSubmit = () => {
        // this.categoryBasicInfo.validate();
        
        // console.log("on subm<<<<<<<<<<<<<"+this.formRef.current)
        this.formRef.current.validateFields().then(values => {
            

            this.vendorBasicInfoRef.current.setFormActive(false)
            this.vendorExtraInfoRef.current.setFormActive(false)
            
            this.setState({
                loading: true,
            })

            this.createVendor(values)

        }).catch(errorInfo => {
            //console.log("on errorInfo<<<<<<<<<<<values<<"+errorInfo)
            //showErrorToast('Error creating vendor')
            //this.clearValidation();
        });
    }

    async createVendor(data)
    {
        try {
            const dataToPost =  {
                ...data,
                company: data.company || "",
                address: data.address || "",
                gstNo: data.gstNo || "",
                active: data.active || false,
                rank: data.rank || 1,
            };

            const response = this.state.vendor ? await axios.put(baseUrl + "vendor/"+this.state.vendor.id, dataToPost, {withCredentials: true}) 
            : await axios.post(baseUrl + "vendor/create", dataToPost, {withCredentials: true});

            console.log("updating cat<<<<<<<<<<<<<"+response.data.code)

            if (response.data.code == 0) {
                this.setState({ loading: false});
                
                this.onClose()
                showSuccessToast(this.state.vendor ? 'Vendor updated successfully' : 'Vendor created successfully')
               
            } else {
                this.vendorBasicInfoRef.current.setFormActive(true);
                this.vendorExtraInfoRef.current.setFormActive(true);
                this.setState({ loading: false});
                let errorMsg = this.getErrorMsgFromCode(response.data.code);
                showErrorToast(errorMsg)
               
            }
        } catch (err) {
            console.log("Response", err);
            showErrorToast(this.state.vendor ? 'Error updating vendor' : 'Error creating vendor')
        }
    }

    getErrorMsgFromCode(code)
    {
        switch(code)
        {
            case 1002:
            return 'The mobile no is already in use';
            case 1003:
            return 'Email id is already in use';
            default:
            return this.state.vendor ? 'Error updating vendor' : 'Error creating vendor' 
        }
    }
    

    clearValidation = () => {
        const timer = setTimeout(() => {
        
          this.formRef.current.resetFields();
  
        }, 3000);
        return () => clearTimeout(timer);
      }
    
    onFinish = () => {
        console.log("on finishM<MMMMMMM<<<<<<<<<<<<")
    }
    
  

    render() {
        return <Drawer
            title= {this.state.vendor ? "Edit Vendor" : "Add Vendor"}
            width={800}
            
            onClose={this.onClose}
            visible={this.state.visible}
            headerStyle = {{backgroundColor: '#ae543a', color: 'white'}}
            bodyStyle={{ backgroundColor: '#f0f2f5', alignItems: 'center', justifyContent: 'center'}}
            footer={
            <div
                style={{
                textAlign: 'right',
                }}
            >
                <Button onClick={this.onClose} style={{ marginRight: 8 }}>
                Cancel
                </Button>
                <Button onClick={this.onSubmit} type="primary" htmlType="submit" loading = {this.state.loading}>
                {
                    this.state.loading ? (this.state.vendor ? "Updating" : "Creating") :  (this.state.vendor ? "Update" : "Create")
                }
                </Button>
            </div>
            }
        >
           
            <Form layout="vertical"
                onFinish={this.onFinish}
                ref={this.formRef}
                requiredMark="true"
            >
                <VendorBasicInfo ref={this.vendorBasicInfoRef}/>
                <VendorExtraInfo ref={this.vendorExtraInfoRef}/>
            </Form>
           
           
      </Drawer>
    }
}


export default (AddVendor);