import './Categories.less';
import React from 'react';
import { baseUrl } from "../utils/baseUrl";
import axios from "axios";
import { Form, Row, Col, Input, Switch, Card, InputNumber, Select, Upload} from 'antd';
import { showErrorToast } from "../utils/Utils";
import {CheckOutlined, CloseOutlined, LoadingOutlined, PlusOutlined} from '@ant-design/icons';
import ImgCrop from 'antd-img-crop';

const { Option } = Select;
class CategoryBasicInfo extends React.Component {
    state = {
        categories: [],
        active: true,
        parentId: -1,
        previewImage: "",
        fileList: [],
    }
    
    onChange = (value) => {
        console.log(`selected ${value}>>>>>id`+this.state);

        if(this.state.categories) {
           this.setState({
            parentId: this.state.categories[value].id
           })
        }
    }

    getParentId = () => {
      return this.state.parentId
    }

    setParentId = (parentId) => {
      this.setState({
        parentId
       })
       this.getCategories()
    }
      
    onSearch(val) {
      console.log('search:', val);
    }

    componentDidMount()
    {
        if(this.props.category && this.props.category.media.length > 0) {
            const url = this.props.category.media[0]
            const image = url.substring(url.lastIndexOf('/') + 1)
           
            setTimeout(() => {
              this.setMedia(image)
          }, 500)
        }
    }

    componentDidUpdate(prevProps)
    {

    }

    setFormActive = (active) =>
    {
        this.setState({
          active: active
        })
    }

    handleChange = info => {
      let fileList = [...info.fileList];
  
      // // 1. Limit the number of uploaded files
      // // Only to show two recent uploaded files, and old ones will be replaced by the new
      // fileList = fileList.slice(-2);
  
      // 2. Read from response and show file link
      fileList = fileList.map(file => {
        if (file.response) {
          // Component will show file.url as link
          file.url = baseUrl + "media/"+ file.response.name;
        }
        return file;
      });

      const key = "catPhoto"
      var fieldObject = {};
      fieldObject[key] = fileList[0].url
      this.props.formRef.current.setFieldsValue(fieldObject)
      this.setState({ fileList, previewImage: fileList[0].url});
    };


    setMedia = (media) => {
      const fileList = []
      fileList.push({
          url: baseUrl + "media/"+ media
      })
      const key = "catPhoto"
      var fieldObject = {};
      fieldObject[key] = fileList[0].url
      this.props.formRef.current.setFieldsValue(fieldObject)
      this.setState({ fileList, previewImage: fileList[0].url});
  }  

    handlePreview = (file) => {

      console.log("handle preview<<<<<<<"+file.url+":pre:"+file.preview)
      this.setState({
          previewImage: file.url || file.preview
      })
   }
    beforeUpload = (file) => {
      const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
      if (!isJpgOrPng) {
        showErrorToast('You can only upload JPG/PNG file!');
      }
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        showErrorToast('Image must smaller than 2MB!');
      }
      return isJpgOrPng && isLt2M;
    }

    getCategories()
    {
        const myCatId = this.state.category ? this.state.category.id : -1
        axios
        .get(baseUrl + "category/sub/-1?excludeId="+myCatId, { withCredentials: true })
        .then(response => {
            const categories = response.data || [];
       
            this.setState({
                categories: categories,
            });
            if(this.state.parentId !== -1) {
              const findCategory = (element) => element.id === this.state.parentId;
              const index = categories.findIndex(findCategory)
              this.props.formRef.current.setFieldsValue({'category': index})
            }
            
        })
        .catch(error => {
          console.log("Response", error);
        });
    }
   
    render()
    {
        const uploadButton = (
          <div>
              {this.state.loading ? <LoadingOutlined /> : <PlusOutlined />}
              <div style={{ marginTop: 8 }}>Upload</div>
          </div>
        );
        const getFile = (e) => {
          console.log('Upload event:', e);
        
          if (Array.isArray(e)) {
            return e;
          }
         return e && e.fileList;
        };

        return (
        <Card title="Basic Information" >
        <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name="title"
                label="Title"
                rules={[{ required: true, message: 'Please enter title' },
                { min: 3, message: 'Title must be minimum 3 characters.' },
              ]}
              >
                <Input placeholder="Enter title" disabled = {!this.state.active}/>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="alias"
                label="Alias"
              >
                <Input
                  
                  placeholder="Enter alias"
                  disabled = {!this.state.active}
                />
              </Form.Item>
            </Col>
          </Row>
         
          <Row gutter={16}>
              <Col span = {8}>
              <Form.Item
                name="featured"
                label="Featured Category"
                valuePropName="checked"
                >
              <Switch checkedChildren={<CheckOutlined />}
                unCheckedChildren={<CloseOutlined />} disabled = {!this.state.active}/>
              </Form.Item>
                
             
              </Col>
              <Col span = {8}>
              <Form.Item
                name="active"
                label="Activate Category"
                valuePropName="checked"
                initialValue={true}
                >
              <Switch checkedChildren={<CheckOutlined />}
                unCheckedChildren={<CloseOutlined />} disabled = {!this.state.active}/>
              </Form.Item>
                
             
              </Col>
              <Col span={8}>
                <Form.Item
                    name="rank"
                    label="Rank"
                    initialValue={1}
                    rules={[{ required: true, message: 'Please set rank' }]}
                >
                    <InputNumber type="number" disabled = {!this.state.active}/>
                </Form.Item>
             </Col>
          </Row>

          <Row gutter={16}>
            <Col span={24}>
                <Form.Item
                    name="category"
                    label="Parent Category"
                    
                >
                <Select
                    showSearch
                    mode="single"
                    allowClear
                    placeholder="None"
                    optionFilterProp="children"
                    onChange={this.onChange}
                    onSearch={this.onSearch}
                    filterOption={(input, option) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                    disabled = {!this.state.active}
                >
                    {this.state.categories.map((object, i) => {
                        return <Option value={i} key={i}>{object.title}</Option>
                    })}
                </Select>
                </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item
                  name={"catPhoto"}
                  label={
                      <Row className="media-label">
                          <Col span={8}>Photo</Col>
                          <Col span={10} offset={6} style={{textAlign: "right"}}>Preferred Size: 512 * 512 px</Col>
                      </Row> 
                  
                  }
                  rules={[{ required: true, message: 'Photo is required' }]}
                  valuePropName="file"
                  getValueFromEvent={getFile}
              >
                  <ImgCrop rotate  disabled = {!this.state.active}>
                      <Upload
                          action={baseUrl + "media"}
                          name="media"
                          withCredentials={true}
                          listType="picture-card"
                          className="avatar-uploader"
                          fileList={this.state.fileList}
                          beforeUpload={this.beforeUpload}
                          onChange={this.handleChange}
                          onPreview={this.handlePreview}
                          showUploadList={false}
                          disabled = {!this.state.active}
                          maxCount={1}
                      >
                          {this.state.fileList.length < 1 && uploadButton}
                          {this.state.fileList.length > 0 && <img alt="photo" style={{ height: '100%', width:'auto' }} src={this.state.previewImage} />}
                      </Upload>
                  </ImgCrop>         
              </Form.Item>
            </Col>
          </Row>
        </Card>
        );
    }
}
export default CategoryBasicInfo;